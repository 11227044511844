
function PlayerCard({ player, parentData }) {

    return (
        <div className="w300 playerCardWithImage cursorPointer mRight12">
        {/*<div className="playerCard cursorPointer">*/}
            <div className="w100 alignCenter">
                <img src={player.image} width="100%"/>
                <p className="pT20 mBottom0 textCenter text20 textUppercase textBolder colGold">{player.name} {player.surname}</p>
                <p className="textCenter text15 textUppercase textBolder colGold">{parentData == "RS" ? player.role : player.role_eng}</p>
            </div>
        </div>
    )
}

export default PlayerCard;
