import { useState, useEffect } from "react"
import moment from "moment"
import axios from "axios"

import Footer from "../../components/fixed/Footer"
import Navbar from "../../components/fixed/NavBar"
import LeagueTable from "../../components/LeagueTable.js"
import NextGame from "../../components/NextGame.js"
import PrevGame from "../../components/PrevGame.js"
import { useLocation, useParams } from "react-router-dom"

function Schedule({parentData, parentEvent}) {

    let paramsString = window.location.search
    let searchParams = new URLSearchParams("id")
    // console.log(searchParams)

    const [view, setView] = useState("table")

    const [tableData, setTableData] = useState([])
    const [lastGame, setLastGame]   = useState({})
    const [lastGames, setLastGames] = useState([])
    const [nextGame, setNextGame]   = useState({})
    const [nextGames, setNextGames] = useState([])

    const logoArray = {
        "Dinamo"                    : "images/rkd2.png",
        "Vojvodina"                 : "images/Teams/vojvodinans.jpg",
        "Proleter"                  : "images/Teams/proleter_zrenjanin.png",
        "Crvena zvezda Primobet"    : "images/Teams/crvena_zvezda.png",
        "Dubočica 54"               : "images/Teams/dubocica_leskovac.png",
        "Radnički"                  : "images/Teams/radnicki_kragujevac.png",
        "Jugović"                   : "images/Teams/jugovic_kac.png",
        "Partizan"                  : "images/Teams/partizan.png",
        "Vranje 1957"               : "images/Teams/vranje.png",
        "Ub"                        : "images/Teams/rkub.jpg",
        "Obilić"                    : "images/Teams/obilic_beograd.png",
        "Metaloplastika Elixir"     : "images/Teams/meteloplastika.png"
    }

    useEffect(() => {
        axios.get('https://srl.rs/phprest/api/read.php?id=41')
            .then(response => {
                // console.log(response?.data ?? [])
                setTableData(response?.data ?? [])
            }).catch(err => console.log(err))
    }, [])

    useEffect(() => {
        axios.get('https://srl.rs/phprest/api/read_utakmice.php?id=41&ekipa=10500')
            .then(response => {
                const date      = moment()
                let diff        = 10000000
                let diff2       = -10000000
                let last        = {}
                let next        = {}
                let nextArray   = []
                let prevArray   = []

                response?.data.map((val, i) => {
                    if (val.vreme_odigravanja) {

                        const a = date.diff(moment(val.vreme_odigravanja), 'minutes')

                        if (! isNaN(a) && a > 0 && a < diff) {
                            diff = a
                            last = val
                        }

                        if (! isNaN(a) && a < 0 && a > diff2) {
                            diff2 = a
                            next = val
                        }

                        if (a > 0) {
                            prevArray.push(val)
                        } else {
                            nextArray.push(val)
                        }
                    } else {
                        nextArray.push(val)
                    }
                })

                setLastGame(last)
                setLastGames(prevArray)
                setNextGame(next)
                setNextGames(nextArray)
            }).catch(err => console.log(err))
    }, [])

    useEffect(() => {
        const params    = new URLSearchParams(window.location.search)
        const id          = params.get('id')

        if (id) setView(id)
    }, [])

    return (
        <>
            <div className="w100">
                <div id="top_slider_wrapper" className="w100 h300 bg-black">

                    <Navbar parentData={parentData} parentEvent={parentEvent} />

                    <div className="w100 verticalAlignMid pTop200">
                        {parentData == "RS" ? <label className="pageLabel textBolder colGold textUppercase">Raspored</label> :
                        <label className="pageLabel textBolder colGold textUppercase">Schedule</label>}
                    </div>
                </div>

                <div id="schedule_content_wrapper">
                    <div id="filter_schedule" className="w100 verticalAlignMid">
                        <div className="w60 textCenter pY20">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center">
                                        {parentData == "RS" ?
                                            <ul className="col container-filter portfolioFilte list-unstyled mb-0" id="filter">
                                                <li><a onClick={(() => setView("table"))} className={(view == "table" ? "active textBolder" : "textBolder")}>Tabela</a></li>
                                                <li><a onClick={(() => setView("next"))} className={(view == "next" ? "active textBolder" : "textBolder")}>Predstojeće utakmice</a></li>
                                                <li><a onClick={(() => setView("prev"))} className={(view == "prev" ? "active textBolder" : "textBolder")}>Prethodne utakmice</a></li>
                                            </ul> :
                                            <ul className="col container-filter portfolioFilte list-unstyled mb-0" id="filter">
                                                <li><a onClick={(() => setView("table"))} className={(view == "table" ? "active textBolder" : "textBolder")}>Table</a></li>
                                                <li><a onClick={(() => setView("next"))} className={(view == "next" ? "active textBolder" : "textBolder")}>Next Games</a></li>
                                                <li><a onClick={(() => setView("prev"))} className={(view == "prev" ? "active textBolder" : "textBolder")}>Previous Games</a></li>
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container pY20">

                        {view == "table" && <LeagueTable page="schedule" parentData={parentData} data={tableData} />}

                        {view == "next" && nextGames.map((val, i) => {
                                if (val.id) {
                                    return <NextGame key={i} data={val} logoArray={logoArray} parentData={parentData}/>
                                }
                            })
                        }

                        {view == "prev" && lastGames.map((val, i) => {
                                if (val.id) {
                                    return <PrevGame key={i} data={val} logoArray={logoArray} parentData={parentData}/>
                                }
                            })
                        }

                    </div>
                </div>

                <Footer parentData={parentData} />
            </div>
        </>
    )
}

export default Schedule;
