import { startTransition, useCallback, useEffect, useRef, useState } from "react";

import MUIDataTable from "mui-datatables";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

import {
    addNews,
    deleteNews,
    editNews,
    showNews,
} from "../api"

function AdminNews({ id, table }) {

    const [NewsData, setNewsData]           = useState([])
    const [showNewsForm, setShowNewsForm]   = useState(false)
    const [updateNews, setUpdateNews]       = useState(false)

    const [_, updateState] = useState()
    const forceUpdate = useCallback(() => updateState({}), []);

    const [paragraphsNum, setParagraphsNum] = useState([0])

    const [paragraphs, setParagraphs]       = useState([''])
    const [paragraphsEng, setParagraphsEng] = useState([''])

    const titleRef      = useRef(null)
    const titleEngRef   = useRef(null)
    const textRef       = useRef(null)
    const textEngRef    = useRef(null)
    const imageRef      = useRef(null)
    const filesRef      = useRef(null)

    useEffect(() => {
        async function getData() {
            if (table == "news") {

                let queryString = ""

                if (id) {
                    queryString = `?id=${id}`
                }

                let { data } = await showNews(queryString)

                data = await data.map(function(val, i) {

                    val.files = val.files.map((fileUrl, index) => {
                        return (<img height="50px" className="pTop5" key={index} src={fileUrl}/>)
                    })

                    val.text2       = val.text.slice(0, 49) + '...'
                    val.textEng2    = val.textEng.slice(0, 49) + '...'

                    val.image = (<img height="50px" src={val.image}/>)

                    if (val.jurnalistName) {
                        val.autor = val.jurnalistName + ' ' + val.jurnalistSurname
                    } else {
                        val.autor = (<i className="textBolder">novinar obrisan</i>)
                        // val.autor = (<i>---------------------</i>)
                    }

                    val.action = (
                        <>
                            <button onClick={() => newsDelete(val.id)}>delete</button>
                            <button className="mLeft5" onClick={() => newsUpdate(val.id)}>update</button>
                        </>
                    )

                    return val
                })

                console.log(data)
                setNewsData(data)
            }
        }

        getData()
    },[table])

    const newsDelete = async (newsID) => {

        let formData = {
            newsID : newsID
        }

        if (id) {
            formData.autor_id = id
        }

        let { data } = await deleteNews(formData)

        data = await data.map(function(val, i) {

            val.files = val.files.map((fileUrl, index) => {
                return (<img height="50px" className="pTop5" key={index} src={fileUrl}/>)
            })

            val.text2       = val.text.slice(0, 49) + '...'
            val.textEng2    = val.textEng.slice(0, 49) + '...'

            val.image = (<img height="50px" src={val.image}/>)

            if (val.jurnalistName) {
                val.autor = val.jurnalistName + ' ' + val.jurnalistSurname
            } else {
                val.autor = (<i className="textBolder">novinar obrisan</i>)
                // val.autor = (<i>---------------------</i>)
            }

            val.action = (
                <>
                    <button onClick={() => newsDelete(val.id)}>delete</button>
                    <button className="mLeft5" onClick={() => newsUpdate(val.id)}>update</button>
                </>
            )

            return val
        })

        setNewsData(data)
    }

    useEffect(() => {
        function getData() {
            if (showNewsForm && updateNews) {

                const News = NewsData.filter(val => val.id == updateNews)[0]
                console.log(News)

                titleRef.current.value      = News.title
                titleEngRef.current.value   = News.titleEng

                const text      = News.text.split('|--@--|')
                const textEng   = News.textEng.split('|--@--|')

                console.log(text)
                console.log(textEng)

                setTimeout(() => {
                    setParagraphs(text)
                    setParagraphsEng(textEng)
                    setParagraphsNum([...Array(text.length)].map(() => Math.floor(Math.random() * 9)))
                }, 1000);
            }
        }

        getData()
    },[updateNews])

    useEffect(() => {
        if (! showNewsForm) {
            setUpdateNews(false)
            setParagraphs([''])
            setParagraphsEng([''])
            setParagraphsNum([0])
            forceUpdate()
        }
    }, [showNewsForm])

    const newsUpdate = async (id) => {
        setShowNewsForm(true)
        setUpdateNews(id)
    }

    const newsColumns = [
        {
            name: "title",
            label: "Naslov",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "titleEng",
            label: "Naslov(eng)",
            options: {
                filter: false,
                sort: false,
            },
        },
        // {
        //     name: "text2",
        //     label: "Tekst",
        //     options: {
        //         filter: true,
        //         sort: false,
        //     },
        // },
        // {
        //     name: "textEng2",
        //     label: "Tekst(eng)",
        //     options: {
        //         filter: true,
        //         sort: false,
        //     },
        // },
        {
            name: "image",
            label: "Slika",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "files",
            label: "Ostale slike",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "autor",
            label: "Autor",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "action",
            label: "Opcije",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const newsOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
    };

    const addNewsubmit = async () => {

        const news = new FormData()

        for (let i = 0; i < filesRef.current.files.length; i++) {
            news.append("files" + i, filesRef.current.files[i])
        }

        news.append("image", imageRef.current.files[0])
        news.append("title", titleRef.current.value)
        news.append("title_eng", titleEngRef.current.value)
        news.append("text", JSON.stringify(paragraphs))
        news.append("text_eng", JSON.stringify(paragraphsEng))

        if (id) {
            news.append("autor_id", id)
        }

        if (updateNews) {
            // update

            news.append("id", updateNews)

            let { data } = await editNews(news)
            console.log(data)

            data = await data.map(function(val, i) {

                val.files = val.files.map((fileUrl, index) => {
                    return (<img height="50px" className="pTop5" key={index} src={fileUrl}/>)
                })

                // val.text2       = val.text.slice(0, 49) + '...'
                // val.textEng2    = val.textEng.slice(0, 49) + '...'

                val.image = (<img height="50px" src={val.image}/>)

                if (val.jurnalistName) {
                    val.autor = val.jurnalistName + ' ' + val.jurnalistSurname
                } else {
                    val.autor = (<i className="textBolder">novinar obrisan</i>)
                    // val.autor = (<i>---------------------</i>)
                }

                val.action = (
                    <>
                        <button onClick={() => newsDelete(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => newsUpdate(val.id)}>update</button>
                    </>
                )

                return val
            })

            console.log(data)
            setNewsData(data)
        } else {
            // add new
            let { data } = await addNews(news)

            data = await data.map(function(val, i) {

                val.files = val.files.map((fileUrl, index) => {
                    return (<img height="50px" className="pTop5" key={index} src={fileUrl}/>)
                })

                val.image = (<img height="50px" src={val.image}/>)

                val.action = (
                    <>
                        <button onClick={() => newsDelete(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => newsUpdate(val.id)}>update</button>
                    </>
                )

                return val
            })

            console.log(data)
            setNewsData(data)
        }

        console.log(news)
        setShowNewsForm(false)
        setUpdateNews(false)
        setParagraphs([''])
        setParagraphsEng([''])
        setParagraphsNum([0])
    }

    const addNewParagrapf = (index, value) => {

        let array       = paragraphs
        array[index]    = value.target.value

        setParagraphs(array)
        forceUpdate()
    }

    const addNewParagrapfEng = (index, value) => {

        let array       = paragraphsEng
        array[index]    = value.target.value

        setParagraphsEng(array)
        forceUpdate()
    }

    const addParagraph = () => {
        const array         = [...paragraphsNum, paragraphsNum.length + 1]
        const arrayPar      = [...paragraphs, '']
        const arrayParEng   = [...paragraphsEng, '']

        setParagraphs(arrayPar)
        setParagraphsEng(arrayParEng)
        setParagraphsNum(array)
    }

    const removeParagraph = () => {

        if (paragraphsNum.length > 1) {

            let arrayPar    = paragraphs
            arrayPar.splice((arrayPar.length - 1), 1)
            setParagraphs(arrayPar)

            let arrayParEng = paragraphsEng
            arrayParEng.splice((arrayParEng.length - 1), 1)
            setParagraphsEng(arrayParEng)

            let array       = paragraphsNum
            array.splice((array.length - 1), 1)
            setParagraphsNum(array)

            forceUpdate()
        }
    }

    return (
        <div className="w80 h100vh backColGold">
            <div className="w100 spaceBetween mTop10">
                <p className="pLeft20 text40 textBolder">News</p>
                <FontAwesomeIcon
                    onClick={() => setShowNewsForm(!showNewsForm)}
                    className="text60 pRight20 cursorPointer"
                    icon={showNewsForm == true ? faMinusCircle : faPlusCircle}
                />
            </div>

            {showNewsForm &&
                (
                    <div className="w100 verticalAlignMid mTop40">
                        <div className="w60 pY30 backColBlack">
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Naslov</label>
                                    <input ref={titleRef} type="text" className="w100" placeholder="Ukucaj naslov"/>
                                </div>
                                <div className="w48">
                                    <label>Naslov(eng)</label>
                                    <input ref={titleEngRef} type="text" className="w100" placeholder="Ukucaj naslov na engleskom"/>
                                </div>
                            </div>
                            {/* <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Tekst</label>
                                    <textarea ref={textRef} className="w100 h200" placeholder="Ukucaj tekst"></textarea>
                                </div>
                                <div className="w48">
                                    <label>Tekst(eng)</label>
                                    <textarea ref={textEngRef} className="w100 h200" placeholder="Ukucaj tekst na engleskom"></textarea>
                                </div>
                            </div> */}


                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Paragraf</label>
                                    {paragraphsNum.map((val, i) => {
                                        return <textarea key={i} onChange={(e) => addNewParagrapf(i, e)} className="w100 mY5" value={paragraphs[i]} placeholder={"paragraf " + i}></textarea>
                                    })}
                                </div>
                                <div className="w48">
                                    <label>Paragraf Eng</label>
                                    {paragraphsNum.map((val, i) => {
                                        return <textarea key={i} onChange={(e) => addNewParagrapfEng(i, e)} className="w100 mY5" value={paragraphsEng[i]} placeholder={"paragraf " + i}></textarea>
                                    })}
                                </div>
                            </div>

                            <div className="w100 mY20">
                                <input type="button" value="Dodaj paragraf" onClick={() => addParagraph()}/>
                                <input type="button" className="mLeft10" onClick={() => removeParagraph()} value="Obrisi paragraf"/>
                            </div>

                            <div className="w100 spaceBetween colGold">
                                <div className="w48 colGold">
                                    <label>Slika</label>
                                    <input ref={imageRef} type="file" accept="image/png, image/gif, image/jpeg" className="w100" placeholder="Izaberi sliku"/>
                                </div>
                                <div className="w48 colGold">
                                    <label>Ostale Slike</label>
                                    <input ref={filesRef} type="file" accept="image/png, image/gif, image/jpeg" className="w100" placeholder="Izaberi slike" multiple={true}/>
                                </div>
                            </div>
                            <div className="w100 verticalAlignMid mTop10">
                                <button onClick={addNewsubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="w100 verticalAlignMid mY20">
                <div className="w90 mTop20 verticalAlignMid">
                    <MUIDataTable
                        title={"News Table"}
                        data={NewsData}
                        columns={newsColumns}
                        options={newsOptions}
                        className={"w100"}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminNews;
