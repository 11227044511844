import { useEffect, useRef, useState } from "react"

import { authAdmin, authJournalist, logout, sendAdminMail, sendJournalistMail } from "../../api"

import AdminManagement      from "../../components/AdminManagement"
import AdminDashboard       from "../../components/AdminDashboard"
import AdminPlayers         from "../../components/AdminPlayers"
import AdminNews            from "../../components/AdminNews"
import AdminPartners        from "../../components/AdminPartners"
import AdminSocial          from "../../components/AdminSocial"
import AdminTopSlider       from "../../components/AdminTopSlider"
import AdminLeagues         from "../../components/AdminLeagues"
import AdminInternational   from "../../components/AdminInternational"
import AdminFolders         from "../../components/AdminFolders"
import AdminFile            from "../../components/AdminFile"

import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import AdminLiveStream from "../../components/AdminLiveStream"

function Admin(parentData) {

    const [table, setTable] = useState("")

    const navigate = useNavigate()
    const location = useLocation()

    const [params, setParams] = useSearchParams()

    const jwt = params.get("jwt")
    const uid = params.get("uid")

    useEffect(() => {

        async function getData() {
            console.log(parentData)

            if (location.pathname == "/admin") {
                if (!jwt || jwt == "") {
                    // posalji mail adminu i redirektuj ga

                    sendAdminMail()
                    navigate("/")
                } else {
                    // proveri da li se jwt poklapa sa jwt-om iz baze

                    const { data } = await authAdmin(jwt)
                    console.log(data)

                    if (data) {
                        // ako se poklapa pokazi mu dashboard
                        setTable("dashboard")
                    } else {
                        // ako se ne poklapa vrati ga na pocetnu
                        navigate("/")
                    }
                }
            } else {
                if (!jwt || jwt == "") {
                    // posalji mail novinaru koji se 'prijavio' i redirektuj ga
                    sendJournalistMail({id : parentData.id})
                    navigate("/")
                } else {
                    // proveri da li se jwt poklapa sa jwt-om iz baze

                    const { data } = await authJournalist({
                        uid,
                        jwt
                    })

                    if (data) {
                        // ako se poklapa pokazi mu dashboard
                        setTable("news")
                    } else {
                        // ako se ne poklapa vrati ga na pocetnu
                        navigate("/")
                    }
                }
            }
        }

        getData()
    }, [])

    const goLogout = async () => {
        await logout(parentData.id)
        navigate('/')
    }

    return (
        <>
            <div className="w100 h100 verticalAlignMid backColBlack alignItemsStart">
                <div className="w20 h100vh backColBlack">
                    <div className="w100 pBottom10 spaceBetween">
                        <p className="colGold text20 textBolder pLeft20">Bojan Slavulj</p>
                        <p onClick={goLogout} className="cursorPointer colGold text15 textBolder pRight20">Odjavi se</p>
                    </div>
                    <div className="mTop20 w100">
                        {!parentData.id &&
                            <>
                                <div onClick={() => setTable("dashboard")} className="w100 cursorPointer">
                                    <p className="colGold text17 textBolder pLeft20">Dashboard</p>
                                </div>
                                <div onClick={() => setTable("management")} className="w100 cursorPointer">
                                    <p className="colGold text17 textBolder pLeft20">Management</p>
                                </div>
                                <div onClick={() => setTable("players")} className="w100 cursorPointer">
                                    <p className="colGold text17 textBolder pLeft20">Players</p>
                                </div>
                            </>
                        }

                        <div onClick={() => setTable("news")} className="w100 cursorPointer">
                            <p className="colGold text17 textBolder pLeft20">News</p>
                        </div>

                        {!parentData.id &&
                            <>
                                <div onClick={() => setTable("partners")} className="w100 cursorPointer">
                                    <p className="colGold text17 textBolder pLeft20">Partners</p>
                                </div>
                                <div onClick={() => setTable("posts")} className="w100 cursorPointer">
                                    <p className="colGold text17 textBolder pLeft20">Posts</p>
                                </div>
                                <div onClick={() => setTable("liveStream")} className="w100 cursorPointer">
                                    <p className="colGold text17 textBolder pLeft20">Live Stream</p>
                                </div>
                                <div onClick={() => setTable("topSlider")} className="w100 cursorPointer">
                                    <p className="colGold text17 textBolder pLeft20">Top Sliders</p>
                                </div>
                                <div onClick={() => setTable("leagues")} className="w100 cursorPointer">
                                    <p className="colGold text17 textBolder pLeft20">Leagues</p>
                                </div>
                                <div onClick={() => setTable("international")} className="w100 cursorPointer">
                                    <p className="colGold text17 textBolder pLeft20">International Game</p>
                                </div>
                                <div onClick={() => setTable("folders")} className="w100 cursorPointer">
                                    <p className="colGold text17 textBolder pLeft20">Folders</p>
                                </div>
                                <div onClick={() => setTable("files")} className="w100 cursorPointer">
                                    <p className="colGold text17 textBolder pLeft20">Files</p>
                                </div>
                            </>
                        }

                    </div>
                </div>

                {!parentData.id && table == "dashboard" && <AdminDashboard table={table} />}

                {!parentData.id && table == "management" && <AdminManagement table={table}/>}

                {!parentData.id && table == "players" && <AdminPlayers table={table}/>}

                {table == "news" && <AdminNews id={parentData.id} table={table}/>}

                {!parentData.id && table == "partners" && <AdminPartners table={table}/>}

                {!parentData.id && table == "posts" && <AdminSocial table={table}/>}

                {!parentData.id && table == "liveStream" && <AdminLiveStream table={table}/>}

                {!parentData.id && table == "topSlider" && <AdminTopSlider table={table}/>}

                {!parentData.id && table == "leagues" && <AdminLeagues table={table}/>}

                {!parentData.id && table == "international" && <AdminInternational table={table}/>}

                {!parentData.id && table == "folders" && <AdminFolders table={table}/>}

                {!parentData.id && table == "files" && <AdminFile table={table}/>}

            </div>
        </>
    )
}

export default Admin
