import { useEffect, useRef, useState } from "react";

import MUIDataTable from "mui-datatables";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

import {
    addFolder,
    showFolders,
    editFolder,
    deleteFolder
} from "../api";

function AdminFolders({ table }) {

    const [foldersData, setFoldersData]             = useState([])
    const [showFoldersForm, setShowFoldersForm]     = useState(false)
    const [updateFolders, setUpdateFolders]         = useState(false)

    const imageRef      = useRef(null)
    const titleRef      = useRef(null)

    const foldersColumns = [
        {
            name: "title",
            label: "Naziv",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "image",
            label: "Slika",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "action",
            label: "",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const foldersOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
    };

    useEffect(() => {
        async function getData() {
            if (table == "folders") {

                let { data } = await showFolders()

                data = await data.map(function(val, i) {

                    val.image = (<img height="50px" src={val.image}/>)

                    val.action = (
                        <>
                            <button onClick={() => foldersDelete(val.id)}>delete</button>
                            <button className="mLeft5" onClick={() => foldersUpdate(val.id)}>update</button>
                        </>
                    )

                    return val
                })

                setFoldersData(data)
            }
        }

        getData()
    }, [table])

    useEffect(() => {
        if (showFoldersForm && updateFolders) {

            const folder = foldersData.filter(val => val.id == updateFolders)[0]
            console.log(folder)

            titleRef.current.value = folder.title
        }
    },[updateFolders])

    const foldersDelete = async (id) => {

        let { data } = await deleteFolder(id)

        data = await data.map(function(val, i) {

            val.image = (<img height="50px" src={val.image}/>)

            val.action = (
                <>
                    <button onClick={() => foldersDelete(val.id)}>delete</button>
                    <button className="mLeft5" onClick={() => foldersUpdate(val.id)}>update</button>
                </>
            )

            return val
        })

        setFoldersData(data)
    }

    const foldersUpdate = async (id) => {
        setShowFoldersForm(true)
        setUpdateFolders(id)
    }

    const addFolderSubmit = async () => {

        const folder = new FormData()

        folder.append("image", imageRef.current.files[0])
        folder.append("title", titleRef.current.value)

        if (updateFolders) {
            folder.append("id", updateFolders)

            let { data } = await editFolder(folder)

            data = await data.map(function(val, i) {

                val.image = (<img height="50px" src={val.image}/>)

                val.action = (
                    <>
                        <button onClick={() => foldersDelete(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => foldersUpdate(val.id)}>update</button>
                    </>
                )

                return val
            })

            setFoldersData(data)
        } else {
            // add new
            let { data } = await addFolder(folder)

            data = await data.map(function(val, i) {

                val.image = (<img height="50px" src={val.image}/>)

                val.action = (
                    <>
                        <button onClick={() => foldersDelete(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => foldersUpdate(val.id)}>update</button>
                    </>
                )

                return val
            })

            setFoldersData(data)
        }

        setShowFoldersForm(false)
        setUpdateFolders(false)
    }

    return (
        <div className="w80 h100vh backColGold">
            <div className="w100 spaceBetween mTop10">
                <p className="pLeft20 text40 textBolder">Folderi</p>
                <FontAwesomeIcon
                    onClick={() => setShowFoldersForm(!showFoldersForm)}
                    className="text60 pRight20 cursorPointer"
                    icon={showFoldersForm == true ? faMinusCircle : faPlusCircle}
                />
            </div>

            {showFoldersForm &&
                (
                    <div className="w100 verticalAlignMid mTop40">
                        <div className="w60 pY30 backColBlack">
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Naslov</label>
                                    <input ref={titleRef} type="text" className="w100" placeholder="Ukucaj naslov"/>
                                </div>
                                <div className="w48 colGold">
                                    <label>Slika</label>
                                    <input ref={imageRef} type="file" accept="image/png, image/gif, image/jpeg" className="w100" placeholder="Izaberi sliku"/>
                                </div>
                            </div>
                            <button onClick={addFolderSubmit}>Submit</button>
                        </div>
                    </div>
                )
            }

            <div className="w100 verticalAlignMid mY20">
                <div className="w90 mTop20 verticalAlignMid">
                    <MUIDataTable
                        title={"Folders Table"}
                        data={foldersData}
                        columns={foldersColumns}
                        options={foldersOptions}
                        className={"w100"}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminFolders;