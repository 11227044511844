
import { useEffect, useState } from "react";
import Footer from "../../components/fixed/Footer"
import Navbar from "../../components/fixed/NavBar"
import { showFolders, showFiles } from "../../api";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

function Gallery({parentData, parentEvent}) {

    const [folder, setFolder]   = useState(false)
    const [index, setIndex]     = useState(-1)

    const [folderData, setFolderData]   = useState([])
    const [fileData, setFileData]       = useState([])
    const [slides, setSlides]           = useState([])

    const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

    useEffect(() => {
        async function getData() {
            const { data } = await showFolders()
            // console.log(data)
            setFolderData(data)
        }

        getData()
    }, [])

    useEffect(() => {
        async function getData() {
            if (! folder) return

            const { data } = await showFiles(folder)

            await data.map(function(val, i) {
                val.src     = val.url
                val.height  = Math.round((val.height / val.width) * 1080)
                val.width   = 1080
            })

            const data2 = data.map(function(val, i) {
                const width = val.width * 4;
                const height = val.height * 4;
                return {
                    src: val.url,
                    width,
                    height,
                    srcSet: breakpoints.map((breakpoint) => {
                        const breakpointHeight = Math.round((height / width) * breakpoint);
                        return {
                            src: val.url,
                            width: breakpoint,
                            height: breakpointHeight,
                        };
                    }),
                };
            })

            // console.log(data)
            setFileData(data)
            setSlides(data2)
        }

        getData()
    },[folder])

    function checkForFiles(data) {
        if (data.files > 0) setFolder(data.id)
    }

    return (
        <>
            <div className="w100">
                <div id="top_slider_wrapper" className="w100 h300 bg-black">

                    <Navbar parentData={parentData} parentEvent={parentEvent} />

                    <div className="w100 verticalAlignMid pTop200">
                        {parentData == "RS" ? <label className="pageLabel textBolder colGold textUppercase">Galerija</label> :
                        <label className="pageLabel textBolder colGold textUppercase">Gallery</label>}
                    </div>
                </div>

                <div className="w100 verticalAlignMid">
                    {! folder &&
                        <div id="gallery_folders_wrapper" className="w80 pY30">
                            {folderData.map((val, i) => {
                                return (<div key={i} className="galleryFolderWrapper" onClick={() => checkForFiles(val)}>
                                    <img src={val.image} alt="gallery folder image"/>
                                    <p className="mb-0">{val.title}</p>
                                    <p className="">{val.files} items</p>
                                </div>)
                            })}

                        </div>
                    }

                    {folder &&
                        <div id="gallery_files_wrapper" className="w80 pY30">
                            <div className="w100 verticalAlignStart">
                                <FontAwesomeIcon
                                    onClick={() => setFolder(false)}
                                    className="text60 pRight20 cursorPointer"
                                    icon={faArrowLeft}
                                />
                               </div>

                            <PhotoAlbum photos={fileData} layout="rows" onClick={({ index }) => setIndex(index)} className="w100 mTop20"/>

                            <Lightbox
                                slides={slides}
                                open={index >= 0}
                                index={index}
                                close={() => setIndex(-1)}
                                // enable optional lightbox plugins
                                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                            />
                        </div>
                    }
                </div>

                <Footer parentData={parentData} />
            </div>
        </>
    )
}

export default Gallery;
