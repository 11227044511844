import { useEffect, useRef, useState } from "react";

import MUIDataTable from "mui-datatables";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

import {
    addMember,
    deleteManagement,
    editMember,
    getManagement,
    getPositions
} from "../api";

function AdminManagement({ table }) {

    const [managementData, setManagementData]   = useState([])

    const [allPositions, setAllPositions]       = useState([])

    const [showManagementForm, setShowManagementForm]   = useState(false)
    const [updateMember, setUpdateMember]               = useState(false)

    const nameRef       = useRef(null)
    const surnameRef    = useRef(null)
    const positionRef   = useRef(null)
    const imageRef      = useRef(null)
    const emailRef      = useRef(null)

    useEffect(() => {

        async function getData() {

            const { data } = await getPositions()
            // console.log(data)
            setAllPositions(data)
        }

        getData()
    },[])

    useEffect(() => {

        async function getData() {
            if (table == "management") {
                let { data } = await getManagement()

                data = await data.map(function(val, i) {

                    val.image = (<img height="50px" src={val.image}/>)

                    val.action = (
                        <>
                            <button onClick={() => deleteManagementMember(val.id)}>delete</button>
                            <button className="mLeft5" onClick={() => updateManagementMember(val.id)}>update</button>
                        </>
                    )
                    return val
                })

                console.log(data)
                setManagementData(data)
            }
        }

        getData()
    },[table])

    const managementColumns = [
        {
            name: "name",
            label: "Ime",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "surname",
            label: "Prezime",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "role",
            label: "Pozicija",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "image",
            label: "Slika",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "action",
            label: "Opcije",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const managementOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
    };

    async function deleteManagementMember(id) {

        let { data } = await deleteManagement(id)

        data = await data.map(function(val, i) {

            val.image = (<img height="50px" src={val.image}/>)

            val.action = (
                <>
                    <button onClick={() => deleteManagementMember(val.id)}>delete</button>
                    <button className="mLeft5" onClick={() => updateManagementMember(val.id)}>update</button>
                </>
            )
            return val
        })

        setManagementData(data)
    }

    useEffect(() => {
        if (showManagementForm && updateMember) {

            const member = managementData.filter(val => val.id == updateMember)[0]
            console.log(member)

            nameRef.current.value       = member.name
            surnameRef.current.value    = member.surname
            positionRef.current.value   = member.team_positionID
            emailRef.current.value      = member.email
        }
    },[updateMember])

    useEffect(() => {
        if (! showManagementForm) setUpdateMember(false)
    }, [showManagementForm])

    function updateManagementMember(id) {
        setShowManagementForm(true)
        setUpdateMember(id)
    }

    const addMemberSubmit = async () => {

        const member = new FormData()

        member.append("image", imageRef.current.files[0])
        member.append("name", nameRef.current.value)
        member.append("surname", surnameRef.current.value)
        member.append("position", positionRef.current.value)
        member.append("email", emailRef.current.value)

        if (updateMember) {
            // update

            member.append("id", updateMember)

            let { data } = await editMember(member)
            console.log(data)

            data = await data.map(function(val, i) {

                val.image = (<img height="50px" src={val.image}/>)

                val.action = (
                    <>
                        <button onClick={() => deleteManagementMember(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => updateManagementMember(val.id)}>update</button>
                    </>
                )
                return val
            })

            console.log(data)
            setManagementData(data)
        } else {
            // add new
            let { data } = await addMember(member)

            data = await data.map(function(val, i) {

                val.image = (<img height="50px" src={val.image}/>)

                val.action = (
                    <>
                        <button onClick={() => deleteManagementMember(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => updateManagementMember(val.id)}>update</button>
                    </>
                )
                return val
            })

            console.log(data)
            setManagementData(data)
        }

        console.log(member)
        setShowManagementForm(false)
        setUpdateMember(false)
    }

    return (
        <div className="w80 h100vh backColGold">
            <div className="w100 spaceBetween mTop10">
                <p className="pLeft20 text40 textBolder">Management</p>
                <FontAwesomeIcon
                    onClick={() => setShowManagementForm(!showManagementForm)}
                    className="text60 pRight20 cursorPointer"
                    icon={showManagementForm == true ? faMinusCircle : faPlusCircle}
                />
            </div>

            {showManagementForm &&
                (
                    <div className="w100 verticalAlignMid mTop40">
                        <div className="w60 pY30 backColBlack">
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Ime</label>
                                    <input ref={nameRef} type="text" className="w100" placeholder="Ukucaj Ime"/>
                                </div>
                                <div className="w48">
                                    <label>Prezime</label>
                                    <input ref={surnameRef} type="text" className="w100" placeholder="Ukucaj prezime"/>
                                </div>
                            </div>
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label className="w100">Pozicija</label>
                                    <select ref={positionRef}>
                                        <option value="">Odaberi poziciju</option>
                                        {
                                            allPositions.map(function(val, i) {
                                                return <option key={i} value={val.id}>{val.role}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="w48">
                                    <label>Slika</label>
                                    <input ref={imageRef} type="file" accept="image/png, image/gif, image/jpeg" className="w100" placeholder="Izaberi sliku"/>
                                </div>
                            </div>
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Enter your email:</label>
                                    <input placeholder="Ukucaj email" type="email" ref={emailRef} name="email"/>
                                </div>
                            </div>

                            <button onClick={addMemberSubmit}>Submit</button>

                        </div>
                    </div>
                )
            }

            <div className="w100 verticalAlignMid mY20">
                <div className="w90 mTop20 verticalAlignMid">
                    <MUIDataTable
                        title={"Management Table"}
                        data={managementData}
                        columns={managementColumns}
                        options={managementOptions}
                        className={"w100"}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminManagement;