import Footer from "../../components/fixed/Footer"
import Navbar from "../../components/fixed/NavBar"

import CountUp from "react-countup"
import ScrollTrigger from "react-scroll-trigger"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faPeopleGroup, faTrophy, faUser } from '@fortawesome/free-solid-svg-icons'
import { useRef, useState } from "react"
import { sendMail } from "../../api"

function Contact({parentData, parentEvent}) {

    const [counter, setCounter]         = useState(false)
    const [errorMail, setErrorMail]     = useState(false)
    const [successMail, setSuccessMail] = useState(false)

    const nameRef       = useRef(null)
    const mailRef       = useRef(null)
    const subjectRef    = useRef(null)
    const messageRef    = useRef(null)

    const sendMailSubmit = async (e) => {

        e.preventDefault()

        const mail = {
            name    : nameRef.current.value,
            email   : mailRef.current.value,
            subject : subjectRef.current.value,
            message : messageRef.current.value,
        }

        if (! mail.name || ! mail.email || ! mail.subject || ! mail.message ) {
            setErrorMail("Morate da popunite sva polja!")
            setSuccessMail(false)
        } else {
            const { data } = await sendMail(mail)

            if (data) {
                emptyFormFields()
                setErrorMail(false)
                setSuccessMail("E-mail je uspesno poslat!")

                setTimeout(() => setSuccessMail(false), 5000)
            } else {
                setErrorMail("Nesto je poslo po zlu, pokusajte kasnije!")
                setSuccessMail(false)
            }
        }
    }

    const emptyFormFields = () => {
        nameRef.current.value       = ""
        mailRef.current.value       = ""
        subjectRef.current.value    = ""
        messageRef.current.value    = ""
    }

    return (
        <>
            <div className="w100">
                <div id="top_slider_wrapper" className="w100 h300 bg-black">

                    <Navbar parentData={parentData} parentEvent={parentEvent} />

                    <div className="w100 verticalAlignMid pTop200">
                        {parentData == "RS" ? <label className="pageLabel textBolder colGold textUppercase">Kontakt</label> :
                        <label className="pageLabel textBolder colGold textUppercase">Contact</label>}
                    </div>
                </div>
                <div className="mY100">
                    <div className="w100 verticalAlignMid">
                        <div className="w80">
                            {parentData == "RS" ? <p className="text40 textBolder">Kratak istorijat kluba</p> :
                            <p className="text40 textBolder">A brief history of the Club</p>}
                        </div>
                    </div>
                    <div className="w100 verticalAlignMid">
                        <div className="w80 spaceBetween">
                            <div className="w100">
                                {parentData == "RS" ?
                                    <p className="w100">
                                        Rukometni klub Dinamo iz Pančeva je osnovan 26. septembra 1948. godine, a kao
                                        osnivači kluba beleže se Margan Bogdan, Dejanović Vladimir, Jovančić Branislav i Đulizibarić Đuza.  <br/><br/>
                                        Već u prvoj deceniji postojanja, 1955. i 1957. godine, RK DINAMO je
                                        postao prvak Jugoslavije u velikom rukometu.                                                        <br/><br/>
                                        Vicešampion države RK Dinamo je bio tri puta (1970. 1982. i 2017. godine), u polufinalu KUP-a
                                        SFRJ igrao je osam puta, a finalista Kupa SFRJ bio je 1972. godine.                                 <br/><br/>
                                        Rukometni klub Dinamo je tri puta osvojio pehar namenjen najboljem u KUP-u
                                        Vojvodine i to 2009, 2013. i 2016. godine.                                                          <br/><br/>
                                        RK Dinamo je bio i prvak Prve lige, grupa Sever, a to se dogodilo 2014. nakon čega se Klub
                                        plasirao u Super B rukometnu ligu Srbije. U sezoni 2015 / 2016 RK DINAMO se, kao
                                        prvak SBRLS plasirao u Superligu Srbije i odmah, sledeće sezone, postao vicešampion države.         <br/><br/>
                                        U sezoni 2017 / 2018 RK DINAMO je, kao drugi predstavnik Republike Srbije,
                                        zabeležio debitantski nastup u regionalnoj SEHA - GAZPROM ligi.                                     <br/><br/>
                                        Rukometni klub DINAMO je iznedrio veliki broj državnih reprezentativaca kao što su Krstić Milan,
                                        Perić Bogosav, Fajfrić Petar, Patić Dušan, Pokrajac Branislav, Fejzula Petrit, Kostić Boris,
                                        Kalina Milan, Perić Dejan, Milosavljević Žikica, koji su svi redom nosioci
                                        nacionalnih priznanja i osvajači medalja na velikim takmičenjima.
                                    </p> :
                                    <p className="w100">
                                        Handball club Dinamo from Pancevo was founded on September 26, 1948, and as
                                        the founders of the club are Margan Bogdan, Dejanović Vladimir, Jovančić Branislav and Đulizibarić Đuza.                                <br/><br/>
                                        Already in the first decade of its existence, in 1955 and 1957, RK DINAMO was
                                        became the champion of Yugoslavia in big handball.                                                                                      <br/><br/>
                                        State vice-champion RK Dinamo was three times (in 1970, 1982 and 2017), in the semifinals of the KUP
                                        SFRY played eight times, and was a finalist of the SFRY Cup in 1972.                                                                    <br/><br/>
                                        The handball club Dinamo won the trophy for the best in the Cup three times
                                        of Vojvodina in 2009, 2013 and 2016.                                                                                                    <br/><br/>
                                        RK Dinamo was also the champion of the First League, group North, and that happened in 2014, after which the Club
                                        placed in the Super B handball league of Serbia. In the season 2015 / 2016 RK DINAMO se, like
                                        placed the champion SBRLS in the Super League of Serbia and immediately, the following season, became the vice-champion of the country. <br/><br/>
                                        In the 2017/2018 season, RK DINAMO, as the second representative of the Republic of Serbia,
                                        recorded his debut appearance in the regional SEHA - GAZPROM league.                                                                    <br/><br/>
                                        Handball club DINAMO produced a large number of national representatives such as Krstić Milan,
                                        Perić Bogosav, Fajfrić Petar, Patić Dušan, Pokrajac Branislav, Fejzula Petrit, Kostić Boris,
                                        Kalina Milan, Perić Dejan, Milosavljević Žikica, who are all holders in turn
                                        national awards and medalists at major competitions.
                                    </p>
                                }
                            </div>
                            {/* <div className="w48">
                                <img src="https://upload.wikimedia.org/wikipedia/hr/0/0d/RK_Dinamo_Pancevo.png" className="w100"/>
                            </div> */}
                        </div>
                    </div>
                    <div className="w100 verticalAlignMid">
                        <div className="w80 spaceBetween">
                            {/*<div className="w48">*/}
                            {/*    <img src="" className="w100"/>*/}
                            {/*</div>*/}
                            <div className="w100">
                                {parentData == "RS" ?
                                    <p className="w100">
                                        Jedan od njih, Patić Dušan - Faksi je na svetskom prvenstvu, održanom 1955.
                                        godine u Zapadnoj Nemačkoj, proglašen za najboljeg igrača sveta u velikom rukometu.                 <br/><br/>
                                        Veliki broj bivših igrača DINAMA je po završetku igračke karijere nastavio da se
                                        bavi trenerskim poslom, a najistaknutiji od njih su: Seneši Robert, Brodan Backo,
                                        Janković Ranko - Belmondo, Krstić Milan, Perić Bogosav, Pokrajac Branislav, Kecman Nenad,
                                        Čelar Džogi, Vignjević Ilija, Đekić Milan, Marković Nikola, Perić Dejan, Milosavljević Žikica,
                                        Ivan Petkovic i Ljubomir Obradovic.
                                                                                        <br/><br/>
                                        Neki članovi kluba ostvarali su zapažene karijere i kao rukometni radnici. Margan Bogdan
                                        je bio selektor ženske reprezentacije naše zemlje i generalni sekretar Rukometnog saveza
                                        Jugoslavije. Pupić Petar bio je istaknuti i priznati međunarodni sudija, predsednik RSJ
                                        i prvi čovek Rukometnog saveza Vojvodine. Nekadašnji prvotimac našeg kluba Lukić
                                        Radisav - Bata na mestu sekretara kluba proveo je 22 godine.                                        <br/><br/>
                                        U svojoj istoriji RK DINAMO je odigrao preko 500 međunarodnih utakmica u zemljama
                                        Evrope i Afrike. Trenutno, RK DINAMO je lider u SRLS, bez ijednog poraza,
                                        a naše utakmice prati u proseku oko 800 gledalaca.                                                  <br/><br/>
                                        U ovom trenutku, osim sjajnih rezultata u najvišem rangu, ponosni smo što u klubu
                                        rade sve selekcije, od pionira do prvog tima, što je, takođe, retkost u srpskom sportu.
                                    </p> :
                                    <p className="w100">
                                        One of them, Patić Dušan - Faksi, was at the world championship, held in 1955.
                                        in West Germany, declared the best player in the world in handball.                                                 <br/><br/>
                                        A large number of former DINAM players continued to play after the end of their playing career
                                        deals with coaching, and the most prominent of them are: Senesi Robert, Brodan Backo,
                                        Janković Ranko - Belmondo, Krstić Milan, Perić Bogosav, Pokrajac Branislav, Kecman Nenad,
                                        Čelar Džogi, Vignjević Ilija, Đekić Milan, Marković Nikola, Perić Dejan, Milosavljević Žikica,
                                        the current head of the coaching staff of Dinamo Petković Ivan and the current women's coach
                                        handball team of Serbia Obradović Ljubomir.                                                                         <br/><br/>
                                        Some members of the club achieved notable careers as handball players. Margan Bogdan
                                        was the selector of the women's national team of our country and the general secretary of the Handball Association
                                        of Yugoslavia. Petar Pupić was a distinguished and recognized international judge, president of the RSJ
                                        and the first man of the Vojvodina Handball Association. Former first team member of our club Lukić
                                        Radisav - Bata spent 22 years as club secretary.                                                                    <br/><br/>
                                        In its history, RK DINAMO has played over 500 international matches in countries
                                        Europe and Africa. Currently, RK DINAMO is the leader in SRLS, without a single defeat,
                                        and our matches are watched by an average of 800 spectators.                                                        <br/><br/>
                                        At the moment, apart from the great results in the top tier, we are proud to be in the club
                                        all selections work, from the pioneers to the first team, which is also rare in Serbian sports.
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w100 verticalAlignMid backColBlack">
                    <div id="contact_counters_wrapper" className="w80">
                        <div className="contactCountersDiv pY30 h100 alignCenter textCenter">
                            <FontAwesomeIcon className="text90 colGold" icon={faTrophy} />
                            <ScrollTrigger onEnter={() => setCounter(true)}>
                                <p className="colWhite text60 mBottom0 textBolder">
                                    {counter && <CountUp start={0} end={10} duration={3} delay={0} />}
                                </p>
                            </ScrollTrigger>
                            {parentData == "RS" ? <p className="colGold text30 textBolder">Trofeja</p> :
                            <p className="colGold text30 textBolder">Trophies</p>}
                        </div>
                        <div className="contactCountersDiv pY30 h100 alignCenter textCenter">
                            <FontAwesomeIcon className="text90 colGold" icon={faUser} />
                            <ScrollTrigger onEnter={() => setCounter(true)}>
                                <p className="colWhite text60 mBottom0 textBolder">
                                    {counter && <CountUp start={0} end={102} duration={3} delay={0} />}
                                </p>
                            </ScrollTrigger>
                            {parentData == "RS" ? <p className="colGold text30 textBolder">Igrača</p> :
                            <p className="colGold text30 textBolder">Players</p>}
                        </div>
                        <div className="contactCountersDiv pY30 h100 alignCenter textCenter">
                            <FontAwesomeIcon className="text90 colGold" icon={faPeopleGroup} />
                            <ScrollTrigger onEnter={() => setCounter(true)}>
                                <p className="colWhite text60 mBottom0 textBolder">
                                    {counter && <CountUp start={0} end={5} duration={3} delay={0} />}
                                </p>
                            </ScrollTrigger>
                            {parentData == "RS" ? <p className="colGold text30 textBolder">Ekipa</p> :
                            <p className="colGold text30 textBolder">Teams</p>}
                        </div>
                        <div className="contactCountersDiv pY30 h100 alignCenter textCenter">
                            <FontAwesomeIcon className="text90 colGold" icon={faCalendarDays} />
                            <ScrollTrigger onEnter={() => setCounter(true)}>
                                <p className="colWhite text60 mBottom0 textBolder">
                                    {counter && <CountUp start={0} end={75} duration={3} delay={0} />}
                                </p>
                            </ScrollTrigger>
                            {parentData == "RS" ? <p className="colGold text30 textBolder">Godina</p> :
                            <p className="colGold text30 textBolder">Years</p>}
                        </div>
                    </div>
                </div>
                <div className="w100 verticalAlignMid mY100">
                    <section className="contact-us" id="contact">
                        <div className="container">
                            <div className="row verticalAlignMid">
                                <div className="col-xl-8 col-lg-8">
                                    <div className="title textCenter">
                                        {parentData == "RS" ?
                                            <h2>Kontaktirajte Nas</h2> :
                                            <h2>Contact Us</h2>
                                        }

                                        {parentData == "RS" ? <p>Na sva Vaša pitanja odgovorićemo u najkracem roku.</p> :
                                        <p>We will answer all your questions as soon as possible.</p>}

                                        <div className="border"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="displayWrap">
                                <div className="contact-details col-md-6">
                                    {parentData == "RS" ? <h3 className="mb-3">Kontakt Detalji</h3> :
                                    <h3 className="mb-3">Contact Details</h3>}

                                    {parentData == "RS" ? <p className="pRight10">Ako Vam zatrebaju dodatne informacije, molimo Vas da nam se obratite.</p> :
                                    <p className="pRight10">If you need additional information, please contact us.</p>}

                                    <p className="text20 textBolder"> RK Dinamo info</p>
                                    <ul className="contact-short-info">
                                        <li className="mb-3">
                                            <span>{parentData == "RS" ? "Adresa" : "Address"}: Masarikova 1a, 26000 Pančevo, Srbija</span>
                                        </li>
                                        <li className="mb-3">
                                            <span>{parentData == "RS" ? "Telefon" : "Phone"}: +381 66 341 594</span>
                                        </li>
                                        <li>
                                            <span>E-mail: dinamo.rk.pancevo@gmail.com</span>
                                        </li>
                                    </ul>
                                    <p className="text20 textBolder"> Rukometna Akademija Dinamo info</p>
                                    <ul className="contact-short-info">
                                        <li className="mb-3">
                                            <span>{parentData == "RS" ? "Adresa" : "Address"}: Srbija – 26000 Pančevo, Jadranska 29</span>
                                        </li>
                                        <li className="mb-3">
                                            <span>{parentData == "RS" ? "Telefon" : "Phone"}: +381 69 27 60 324 (Goran Belić)</span>
                                        </li>
                                        <li>
                                            <span>E-mail: dinamoakademija@gmail.com</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="contact-form col-md-6">
                                    <form id="contact-form" method="post" role="form" onSubmit={sendMailSubmit}>
                                        <div className="form-group mb-4">
                                            <input type="text" ref={nameRef} placeholder={parentData == "RS" ? "Vaše Ime" : "Your Name"} className="form-control" name="name" id="name" required=""/>
                                        </div>
                                        <div className="form-group mb-4">
                                            <input type="email" ref={mailRef} placeholder={parentData == "RS" ? "Vaš Email" : "Your Email"} className="form-control" name="email" id="email" required=""/>
                                        </div>
                                        <div className="form-group mb-4">
                                            <input type="text" ref={subjectRef} placeholder={parentData == "RS" ? "Naslov" : "Subject"} className="form-control" name="subject" id="subject" required=""/>
                                        </div>
                                        <div className="form-group mb-4">
                                            <textarea rows="6" ref={messageRef} placeholder={parentData == "RS" ? "Poruka" : "Message"} className="form-control" name="message" id="message" required=""></textarea>
                                        </div>
                                        <div id="cf-submit">
                                            <input type="submit" id="contact-submit" className="btn btn-transparent" value={parentData == "RS" ? "Pošalji" : "Submit"}/>
                                        </div>
                                    </form>

                                    {errorMail &&
                                        <div className="w100 p20 backColLightRed verticalAlignMid textCenter">
                                            <p className="text30 mBottom0 colWhite">{errorMail}</p>
                                        </div>
                                    }

                                    {successMail &&
                                        <div className="w100 p20 backColLightGreen textCenter">
                                            <p className="text30 mBottom0 colWhite">{successMail}</p>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer parentData={parentData} />
            </div>
        </>
    )
}

export default Contact;
