import { useNavigate } from "react-router-dom"

function LeagueTable({ page, parentData, data }) {

    const navigate = useNavigate()

    data = data.sort((a, b) => a.mesto > b.mesto ? 1 : -1)

    const className = page != "schedule" ? "" : ""

    return (
        <div id="league_table_wrapper" className={className}>
            <div className="w100 backColBlack">
                {parentData == "RS" ? <p className="text20 colGold textBolder textUppercase p20 mBottom0">Tabela lige</p> :
                <p className="text20 colGold textBolder textUppercase p20 mBottom0">League Table</p>}
            </div>

            <div className="w100 verticalAlignMid backColBlackBlurr">
                <div className="mTopMinus10 width75">
                    <div className="w100 pY10 textBolder backColGold textCenter">No.</div>

                    {data.map((val, i) => {
                        const color = val.id == 226 ? "colGold" : "colWhite"
                        return <div key={i} className={"w100 pY10 textBolder textCenter " + color}>{val.mesto}</div>
                    })}

                </div>
                <div className="mTopMinus10 width225">
                    <div className="w100 pY10 textBolder backColGold">Klub</div>

                    {data.map((val, i) => {
                        const color = val.id == 226 ? "colGold" : "colWhite"
                        return <div key={i} className={"w100 pY10 textBolder " + color}>RK {val.ekipa}</div>
                    })}

                </div>
                <div className="mTopMinus10 pY10 textBolder verticalAlignMid leagueTableScroll">
                    <div className="col-md-1 columnsInScroll">
                        <div className="w100 pY10 textBolder textCenter backColGold">Uk.</div>
                        {data.map((val, i) => {
                            const color = val.id == 226 ? "colGold" : "colWhite"
                            return <div key={i} className={"w100 pY10 textBolder textCenter " + color}>{val.odigrano}</div>
                        })}
                    </div>
                        <div className="col-md-1 columnsInScroll">
                        <div className="w100 pY10 textBolder textCenter backColGold">Pob.</div>
                        {data.map((val, i) => {
                            const color = val.id == 226 ? "colGold" : "colWhite"
                            return <div key={i} className={"w100 pY10 textBolder textCenter " + color}>{val.pobede}</div>
                        })}
                    </div>
                        <div className="col-md-1 columnsInScroll">
                        <div className="w100 pY10 textBolder textCenter backColGold">Ner.</div>
                        {data.map((val, i) => {
                            const color = val.id == 226 ? "colGold" : "colWhite"
                            return <div key={i} className={"w100 pY10 textBolder textCenter " + color}>{val.odigrano - val.pobede - val.porazi}</div>
                        })}
                    </div>
                        <div className="col-md-1 columnsInScroll">
                        <div className="w100 pY10 textBolder textCenter backColGold">Por.</div>
                        {data.map((val, i) => {
                            const color = val.id == 226 ? "colGold" : "colWhite"
                            return <div key={i} className={"w100 pY10 textBolder textCenter " + color}>{val.porazi}</div>
                        })}
                    </div>
                        <div className="col-md-1 columnsInScroll">
                        <div className="w100 pY10 textBolder textCenter backColGold">Dao.</div>
                        {data.map((val, i) => {
                            const color = val.id == 226 ? "colGold" : "colWhite"
                            return <div key={i} className={"w100 pY10 textBolder textCenter " + color}>{val.dao}</div>
                        })}
                    </div>
                        <div className="col-md-1 columnsInScroll">
                        <div className="w100 pY10 textBolder textCenter backColGold">Primio.</div>
                        {data.map((val, i) => {
                            const color = val.id == 226 ? "colGold" : "colWhite"
                            return <div key={i} className={"w100 pY10 textBolder textCenter " + color}>{val.primio}</div>
                        })}
                    </div>
                        <div className="col-md-1 columnsInScroll">
                        <div className="w100 pY10 textBolder textCenter backColGold">Raz.</div>
                        {data.map((val, i) => {
                            const color = val.id == 226 ? "colGold" : "colWhite"
                            return <div key={i} className={"w100 pY10 textBolder textCenter " + color}>{Math.abs(val.dao - val.primio)}</div>
                        })}
                    </div>
                        <div className="col-md-1 columnsInScroll">
                        <div className="w100 pY10 textBolder textCenter backColGold">Bod.</div>
                        {data.map((val, i) => {
                            const color = val.id == 226 ? "colGold" : "colWhite"
                            return <div key={i} className={"w100 pY10 textBolder textCenter " + color}>{val.broj_bodova}</div>
                        })}
                    </div>

                </div>
            </div>

            {page != "schedule" &&
                <div className="w100 textCenter mTop20">
                    {parentData == "RS" ? <a id="see_full_table" onClick={() => navigate('/raspored?id=table')}>Pogledaj sve rezultate -{">"}</a> :
                    <a id="see_full_table" onClick={() => navigate('/schedule?id=table')}>Pogledaj sve rezultate -{">"}</a>}
                </div>
            }
        </div>
    )
}

export default LeagueTable;