import moment from 'moment'
import { useNavigate } from 'react-router-dom'

function NewsCard({ news, parentData }) {

    const navigate = useNavigate()

    news.text       = news.text.split('|--@--|')[0].slice(0, 99) + '...'
    news.text_eng   = news.text_eng.split('|--@--|')[0].slice(0, 99) + '...'

    const dateNow   = moment(new Date())
    let duration    = moment.duration(dateNow.diff(news.created_at))

    if (parseInt(duration.asMinutes()) < 60) {
        duration = parseInt(duration.asMinutes()) + ' minutes ago'
    } else if (parseInt(duration.asHours()) < 24) {
        duration = parseInt(duration.asHours()) + ' hours ago'
    } else {
        duration = parseInt(duration.asDays()) + ' days ago'
    }

    return (
        <div className="newsCard newsPageCard cursorPointer" onClick={() => parentData == "RS" ? navigate('/vesti?id=' + news.id) : navigate('/news?id=' + news.id)}>
            <div className="blog-card blog-card-blog">
                <div className="blog-card-image">
                    <a href="#"> <img className="img" src={process.env.PUBLIC_URL + news.image}/> </a>
                    <div className="ripple-cont"></div>
                </div>
                <div className="blog-table">
                    <h6 className="blog-category blog-text-success"><i className="far fa-newspaper"></i> {parentData == "RS" ? "Vest" : "News"}</h6>

                    <h4 className="blog-card-caption blogCardSetup"><a href="#">{parentData == "RS" ? news.title : news.title_eng}</a></h4>

                    {/*<h4 className="blog-card-caption"><a href="#">{parentData == "RS" ? news.title : news.title_eng}</a></h4>*/}

                    <p className="blog-card-description pBottom10">{parentData == "RS" ? news.text : news.text_eng}</p>
                    <div className="ftr borderTopGray1">
                        <div className="author">
                            <p className="text13">{news.name} {news.surname}</p>
                        </div>
                        <div className="stats"> <i className="far fa-clock"></i>
                            <p className="text13">{duration}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsCard;
