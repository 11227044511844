
import Footer from "../../components/fixed/Footer"
import Navbar from "../../components/fixed/NavBar"

function Games({parentData, parentEvent}) {
    return (
        <>
            <div className="w100">
                <div id="top_slider_wrapper" className="w100 h300 bg-black">

                    <Navbar parentData={parentData} parentEvent={parentEvent} />

                    <div className="w100 verticalAlignMid pTop200">
                        <label className="text70 textSpacing20 textBolder colGold textUppercase">Utakmice</label>
                    </div>
                </div>

                <Footer parentData={parentData} />
            </div>
        </>
    )
}

export default Games;
