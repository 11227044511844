import { useEffect, useRef, useState } from "react";

import MUIDataTable from "mui-datatables";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

import {
    addTopSlider,
    showAllSliderTop,
    deleteTopSlider,
    editTopSlider
} from "../api";

function AdminTopSlider({ table }) {

    const [topSliderData, setTopSliderData]           = useState([])
    const [showTopSliderForm, setShowTopSliderForm]   = useState(false)
    const [updateTopSlider, setUpdateTopSlider]       = useState(false)

    const labelRef              = useRef(null)
    const labelEngRef           = useRef(null)
    const headerTopRef          = useRef(null)
    const headerTopEngRef       = useRef(null)
    const headerBottomRef       = useRef(null)
    const headerBottomEngRef    = useRef(null)
    const labelBottomRef        = useRef(null)
    const labelBottomEngRef     = useRef(null)
    const textRef               = useRef(null)
    const textEngRef            = useRef(null)
    const imageRef              = useRef(null)

    const topSliderColumns = [
        {
            name: "image",
            label: "Slika",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "label",
            label: "Gornji Naslov",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "label_eng",
            label: "Gornji Naslov Eng",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "headerTop",
            label: "Gornji Header",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "headerTop_eng",
            label: "Gornji Header Eng",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "headerBottom",
            label: "Donji Header Eng",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "headerBottom_eng",
            label: "Donji Header Eng",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "labelBottom",
            label: "Donji Naslov",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "labelBottom_eng",
            label: "Donji Naslov Eng",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "text",
            label: "Tekst",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "text_eng",
            label: "Tekst Eng",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "action",
            label: "",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const topSliderOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
    };

    useEffect(() => {
        async function getData() {
            if (table == "topSlider") {

                let { data } = await showAllSliderTop()

                data = await data.map(function(val, i) {

                    val.image = (<img height="50px" src={val.image}/>)

                    val.action = (
                        <>
                            <button onClick={() => topSliderDelete(val.id)}>delete</button>
                            <button className="mLeft5" onClick={() => topSliderUpdate(val.id)}>update</button>
                        </>
                    )

                    return val
                })

                console.log(data)
                setTopSliderData(data)
            }
        }

        getData()
    }, [table])

    useEffect(() => {
        if (showTopSliderForm && updateTopSlider) {

            const topSlider = topSliderData.filter(val => val.id == updateTopSlider)[0]
            // console.log(topSlider)

            labelRef.current.value            = topSlider.label
            labelEngRef.current.value         = topSlider.label_eng
            headerTopRef.current.value        = topSlider.headerTop
            headerTopEngRef.current.value     = topSlider.headerTop_eng
            headerBottomRef.current.value     = topSlider.headerBottom
            headerBottomEngRef.current.value  = topSlider.headerBottom_eng
            labelBottomRef.current.value      = topSlider.labelBottom
            labelBottomEngRef.current.value   = topSlider.labelBottom_eng
            textRef.current.value             = topSlider.text
            textEngRef.current.value          = topSlider.text_eng
        }
    },[updateTopSlider])

    const topSliderUpdate = async (id) => {
        setShowTopSliderForm(true)
        setUpdateTopSlider(id)
    }

    const topSliderDelete = async (id) => {

        let { data } = await deleteTopSlider(id)

        data = await data.map(function(val, i) {

            val.image = (<img height="50px" src={val.image}/>)

            val.action = (
                <>
                    <button onClick={() => topSliderDelete(val.id)}>delete</button>
                    <button className="mLeft5" onClick={() => topSliderUpdate(val.id)}>update</button>
                </>
            )

            return val
        })

        setTopSliderData(data)
    }

    const addTopSliderSubmit = async () => {

        const topSlider = new FormData()

        topSlider.append("image", imageRef.current.files[0])
        topSlider.append("label", labelRef.current.value)
        topSlider.append("labelEng", labelEngRef.current.value)
        topSlider.append("headerTop", headerTopRef.current.value)
        topSlider.append("headerTopEng", headerTopEngRef.current.value)
        topSlider.append("headerBottom", headerBottomRef.current.value)
        topSlider.append("headerBottomEng", headerBottomEngRef.current.value)
        topSlider.append("labelBottom", labelBottomRef.current.value)
        topSlider.append("labelBottomEng", labelBottomEngRef.current.value)
        topSlider.append("text", textRef.current.value)
        topSlider.append("textEng", textEngRef.current.value)

        if (updateTopSlider) {

            topSlider.append("id", updateTopSlider)

            // edit
            let { data } = await editTopSlider(topSlider)

            data = await data.map(function(val, i) {

                val.image = (<img height="50px" src={val.image}/>)

                val.action = (
                    <>
                        <button onClick={() => topSliderDelete(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => topSliderUpdate(val.id)}>update</button>
                    </>
                )

                return val
            })

            setTopSliderData(data)
        } else {

            // add new
            let { data } = await addTopSlider(topSlider)

            data = await data.map(function(val, i) {

                val.image = (<img height="50px" src={val.image}/>)

                val.action = (
                    <>
                        <button onClick={() => topSliderDelete(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => topSliderUpdate(val.id)}>update</button>
                    </>
                )

                return val
            })

            setTopSliderData(data)
        }

        setShowTopSliderForm(false)
        setUpdateTopSlider(false)
    }

    return (
        <div className="w80 h100vh backColGold">
            <div className="w100 spaceBetween mTop10">
                <p className="pLeft20 text40 textBolder">Top Slajderi</p>
                <FontAwesomeIcon
                    onClick={() => setShowTopSliderForm(!showTopSliderForm)}
                    className="text60 pRight20 cursorPointer"
                    icon={showTopSliderForm == true ? faMinusCircle : faPlusCircle}
                />
            </div>

            {showTopSliderForm &&
                (
                    <div className="w100 verticalAlignMid mTop40">
                        <div className="w60 pY30 backColBlack">
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Gornji Naslov</label>
                                    <input ref={labelRef} type="text" className="w100" placeholder="Ukucaj gornji naslov"/>
                                </div>
                                <div className="w48">
                                    <label>Gornji Naslov Eng</label>
                                    <input ref={labelEngRef} type="text" className="w100" placeholder="Ukucaj gornji naslov na engleskom"/>
                                </div>
                            </div>
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Gornji Header</label>
                                    <input ref={headerTopRef} type="text" className="w100" placeholder="Ukucaj gornji header"/>
                                </div>
                                <div className="w48">
                                    <label>Gornji Header Eng</label>
                                    <input ref={headerTopEngRef} type="text" className="w100" placeholder="Ukucaj gornji header na engleskom"/>
                                </div>
                            </div>
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Donji Header</label>
                                    <input ref={headerBottomRef} type="text" className="w100" placeholder="Ukucaj donji header"/>
                                </div>
                                <div className="w48">
                                    <label>Donji Header Eng</label>
                                    <input ref={headerBottomEngRef} type="text" className="w100" placeholder="Ukucaj donji header na engleskom"/>
                                </div>
                            </div>
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Donji Naslov</label>
                                    <input ref={labelBottomRef} type="text" className="w100" placeholder="Ukucaj donji naslov"/>
                                </div>
                                <div className="w48">
                                    <label>Donji Naslov Eng</label>
                                    <input ref={labelBottomEngRef} type="text" className="w100" placeholder="Ukucaj donji naslov na engleskom"/>
                                </div>
                            </div>
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Tekst</label>
                                    <textarea ref={textRef} className="w100 h200" placeholder="Ukucaj tekst"></textarea>
                                </div>
                                <div className="w48">
                                    <label>Tekst Eng</label>
                                    <textarea ref={textEngRef} className="w100 h200" placeholder="Ukucaj tekst na engleskom"></textarea>
                                </div>
                            </div>
                            <div className="w100 spaceBetween colGold">
                                <div className="w48 colGold">
                                    <label>Slika</label>
                                    <input ref={imageRef} type="file" accept="image/png, image/gif, image/jpeg" className="w100" placeholder="Izaberi sliku"/>
                                </div>
                            </div>


                            <div className="w100 verticalAlignMid mTop10">
                                <button onClick={addTopSliderSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="w100 verticalAlignMid mY20">
                <div className="w90 mTop20 verticalAlignMid">
                    <MUIDataTable
                        title={"Top Slajderi Table"}
                        data={topSliderData}
                        columns={topSliderColumns}
                        options={topSliderOptions}
                        className={"w100"}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminTopSlider;