import axios from 'axios'

const baseURL   = process.env.NODE_ENV === 'production' ? 'https://rkdinamo.rs/api' : 'http://localhost:8000/api';
const API       = axios.create({ baseURL: baseURL })

export const getHomeData            = ()            => API.get('/')

export const getNews                = (num)         => API.post('/vesti', num)
export const getNewsSingleView      = (id)          => API.get('/vesti?id=' + id)

export const getPlayers             = (formData)    => API.post('/igraci', formData)

export const addMember              = (formData)    => API.post('/createNewManagementMember', formData)
export const editMember             = (formData)    => API.post('/updateManagementMember', formData)
export const getManagement          = ()            => API.get('/showManagementMember')
export const deleteManagement       = (id)          => API.post('/destroyManagementMember', id)

export const getPositions           = ()            => API.get('/teamPositions')
export const getAdminDashboardData  = ()            => API.get('/getAdminDashboardData')

export const showPlayers            = ()            => API.get('/showAllPlayers')
export const addPlayer              = (formData)    => API.post('/createNewPlayer', formData)
export const editPlayer             = (formData)    => API.post('/updatePlayer', formData)
export const deletePlayer           = (id)          => API.post('/destroyPlayer', id)

export const showPartners           = ()            => API.get('/showAllSponsors')
export const addPartner             = (formData)    => API.post('/createNewSponsor', formData)
export const editPartner            = (formData)    => API.post('/updateSponsor', formData)
export const deletePartner          = (id)          => API.post('/destroySponsor', id)

export const showNews               = (queryString) => API.get('/showAllNews' + queryString)
export const addNews                = (formData)    => API.post('/createNews', formData)
export const editNews               = (formData)    => API.post('/updateNews', formData)
export const deleteNews             = (formData)    => API.post('/destroyNews', formData)

export const showActiveStream       = ()            => API.get('/showLiveStream')
export const showLiveStream         = ()            => API.get('/showAllLiveStream')
export const deleteLiveStream       = (id)          => API.post('/destroyLiveStream', id)
export const addLiveStream          = (formData)    => API.post('/createNewLiveStream', formData)

export const showPosts              = ()            => API.get('/showAllPosts')
export const addPost                = (link)        => API.post('/createNewPost', link)
export const deletePost             = (id)          => API.post('/destroyPost', id)

export const getJournalists         = ()            => API.get('/journalists')

export const sendMail               = (formData)    => API.post('/mail', formData)
export const sendJournalistMail     = (formData)    => API.post('/sendJournalistMail', formData)
export const sendAdminMail          = ()            => API.post('/sendAdminMail')
export const authAdmin              = (jwt)         => API.post('/authAdmin', jwt)
export const authJournalist         = (formData)    => API.post('/authJurnalist', formData)

export const showAllSliderTop       = ()            => API.get('/showAllSliderTop')
export const addTopSlider           = (formData)    => API.post('/addTopSlider', formData)
export const editTopSlider          = (formData)    => API.post('/editTopSlider', formData)
export const deleteTopSlider        = (id)          => API.post('/deleteTopSlider', id)

export const showAllLeagues         = ()            => API.get('/showAllLeagues')
export const addLeagues             = (form)        => API.post('/addLeagues', form)
export const editLeagues            = (form)        => API.post('/editLeagues', form)
export const deleteLeagues          = (id)          => API.post('/deleteLeagues', id)

export const showInternational      = ()            => API.get('/getNextInternationalGame')
export const editInternational      = (form)        => API.post('/editNextInternationalGame', form)

export const showFolders            = ()            => API.get('/showAllGalleries')
export const addFolder              = (form)        => API.post('/createNewGallery', form)
export const editFolder             = (form)        => API.post('/editGallery', form)
export const deleteFolder           = (id)          => API.post('/destroyGallery', id)

export const showAllFiles           = ()            => API.get('/showAllFiles')
export const showFiles              = (id)          => API.post('/showFiles', id)
export const addFile                = (form)        => API.post('/createNewFile', form)
export const deleteFile             = (id)          => API.post('/destroyFile', id)

export const logout                 = (id)          => API.post('/logout', id)
