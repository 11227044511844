import { useEffect, useRef, useState } from "react";

import MUIDataTable from "mui-datatables";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

import {
    addPost,
    deletePost,
    showPosts,
} from "../api"

function AdminSocial({ table }) {

    const [postsData, setPostsData]           = useState([])
    const [showPostsForm, setShowPostsForm]   = useState(false)

    const linkRef = useRef(null)

    useEffect(() => {
        async function getData() {
            if (table == "posts") {

                let { data } = await showPosts()

                data = await data.map(function(val, i) {

                    val.url = (
                        <>
                            <a target="_blank" href={val.url}>{val.url}</a>
                        </>
                    )

                    val.action = (
                        <>
                            <button onClick={() => postDelete(val.id)}>delete</button>
                        </>
                    )

                    return val
                })

                console.log(data)
                setPostsData(data)
            }
        }

        getData()
    },[table])

    const postDelete = async (id) => {

        let { data } = await deletePost(id)

        data = await data.map(function(val, i) {

            val.url = (
                <>
                    <a target="_blank" href={val.url}>{val.url}</a>
                </>
            )

            val.action = (
                <>
                    <button onClick={() => postDelete(val.id)}>delete</button>
                </>
            )

            return val
        })

        setPostsData(data)
    }

    const postsColumns = [
        {
            name: "url",
            label: "Link",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "action",
            label: "Opcije",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const postsOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
    };

    const addPostSubmit = async () => {

        let { data } = await addPost(linkRef.current.value)

        data = await data.map(function(val, i) {

            val.url = (
                <>
                    <a target="_blank" href={val.url}>{val.url}</a>
                </>
            )

            val.action = (
                <>
                    <button onClick={() => postDelete(val.id)}>delete</button>
                </>
            )

            return val
        })

        console.log(data)

        setPostsData(data)
        setShowPostsForm(false)
    }

    return (
        <div className="w80 h100vh backColGold">
            <div className="w100 spaceBetween mTop10">
                <p className="pLeft20 text40 textBolder">Post</p>
                <FontAwesomeIcon
                    onClick={() => setShowPostsForm(!showPostsForm)}
                    className="text60 pRight20 cursorPointer"
                    icon={showPostsForm == true ? faMinusCircle : faPlusCircle}
                />
            </div>

            {showPostsForm &&
                (
                    <div className="w100 verticalAlignMid mTop40">
                        <div className="w60 pY30 pX10 backColBlack">
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Link</label>
                                    <input ref={linkRef} type="text" className="w100" placeholder="Ukucaj link"/>
                                </div>
                                <div className="w48 colGold verticalAlignMid">
                                    <button onClick={addPostSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="w100 verticalAlignMid mY20">
                <div className="w90 mTop20 verticalAlignMid">
                    <MUIDataTable
                        title={"Posts Table"}
                        data={postsData}
                        columns={postsColumns}
                        options={postsOptions}
                        className={"w100"}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminSocial;
