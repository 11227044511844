import { Link } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

function Footer({ parentData }) {

    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        {parentData =="RS" ? <h6>O nama</h6> :
                        <h6>About Us</h6>}
                        {parentData == "RS" ?
                            <>
                                <p className="text-justify">
                                    Rukometni klub Dinamo, osnovan je 1948.godine. od tada je učestvovao u
                                    mnogim rangovima takmičenja kako u Jugoslaviji tako i u Srbiji. Dinamo
                                    se u prvim godina svog postojanja okitio i državnim titulama, u svojoj
                                    bogatoj istoriji tu je i još nekoliko vicešampionskih titula, kao i nekoliko
                                    učešća u polufinalima i finalima nacionalog kupa. Od sezone 2015/2016 Dinamo
                                    je standardan učesnik najvišeg ranga takmičenja srpskog rukometa.
                                </p>
                                <p className="text-justify hiddenFooter">
                                    Dinamo takodje ima zebaleženo učešće u regionalnoj SEHA ligi u kojoj je ugostio
                                    velike rukometne klubove, kao i u EHF eurokupu. Mnogi reprezentativci i
                                    uspešni igrači i treneri, započeli su svoju karijeru upravo u Dinamu.
                                    U našoj školi rukometa, prve rukometne korake naučilo je i uči veliki broj
                                    dece u svim uzrasnim kategorijama, a uspešan rad škole rukometa pokazuju i
                                    redovna učešća na državnim prvenstvima kao i nekoliko osvojenih medalja.
                                </p>
                            </> :
                            <>
                                <p className="text-justify">
                                    Handball club Dinamo was founded in 1948. Since then, it has participated
                                    in many levels of competition both in Yugoslavia and in Serbia. In the first
                                    years of its existence, Dinamo won national titles, in its rich history there
                                    are also several vice-championship titles, as well as several participations
                                    in the semi-finals and finals of the national cup. Since the 2015/2016 season,
                                    Dinamo has been a standard participant in the highest level of Serbian handball
                                    competition.
                                </p>
                                <p className="text-justify hiddenFooter">
                                    Dinamo also has a notable participation in the regional SEHA league,
                                    where it hosted big handball clubs, as well as in the EHF Eurocup. Many national
                                    team members and successful players and coaches started their careers in Dinamo.
                                    In our handball school, a large number of children in all age categories learned
                                    and are learning their first handball steps, and the successful work of the
                                    handball school is demonstrated by regular participation in
                                    national championships and several medals won.
                                </p>
                            </>
                        }
                    </div>
                </div>
                <hr/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-sm-6 col-xs-12">
                        <p className="copyright-text">Copyright &copy; 2023 All Rights Reserved</p>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <ul className="social-icons">
                            <li><a className="instagram" href="https://www.instagram.com/rukometni_klub_dinamo_pancevo/" target="_blank">
                                <FontAwesomeIcon
                                    onClick={() => console.log('instagram')}
                                    className="text30 cursorPointer pTop5"
                                    icon={faInstagram}
                                />
                            </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;