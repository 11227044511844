import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment'

function LiveStream({ data, parentData, parentEvent }) {

    // console.log(data)

    data.date = moment(data.date).format("DD/MM/YYYY")

    return (
        <div id="live_stream_wrapper">
            <div className="w100 spaceBetween">
                <p className='text20 pTop20 colGray'>{data.date}</p>
                <FontAwesomeIcon
                    onClick={() => parentEvent(false)}
                    className="text40 pRight10 pTop10 cursorPointer colGray"
                    icon={faCircleXmark}
                />
            </div>
            <div className="w100 mTop10">
                <p className="textBolder text30 colGold">{parentData == "RS" ? data.title : data.title_eng}</p>
            </div>
            <div className="w100 mTop20">
                <a className="cursorPointer text20 colGold w100" href={data.url} target='blank'>{data.url}</a>
            </div>
        </div>
    )
}

export default LiveStream;