import { useEffect, useRef, useState } from "react";

import MUIDataTable from "mui-datatables";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

import {
    addPlayer,
    deletePlayer,
    editPlayer,
    showPlayers,
} from "../api"

function AdminPlayers({ table }) {

    const [playersData, setPlayersData]                 = useState([])
    const [allPositions, setAllPositions]               = useState([])
    const [allTeams, setAllTeams]                       = useState([])
    const [showPlayersForm, setShowPlayersForm]         = useState(false)
    const [updatePlayer, setUpdatePlayer]               = useState(false)

    const nameRef       = useRef(null)
    const surnameRef    = useRef(null)
    const positionRef   = useRef(null)
    const teamRef       = useRef(null)
    const imageRef      = useRef(null)

    // useEffect(() => {

    //     async function getData() {

    //         const { data } = await getPositions()
    //         // console.log(data)
    //         setAllPositions(data)
    //     }

    //     getData()
    // },[])

    useEffect(() => {

        async function getData() {
            if (table == "players") {
                let { data } = await showPlayers()

                data[0] = await data[0].map(function(val, i) {

                    val.image = (<img height="50px" src={val.image}/>)

                    val.action = (
                        <>
                            <button onClick={() => playerDelete(val.id)}>delete</button>
                            <button className="mLeft5" onClick={() => playerUpdate(val.id)}>update</button>
                        </>
                    )

                    return val
                })

                // console.log(data[0])
                setPlayersData(data[0])
                setAllPositions(data[1])
                setAllTeams(data[2])
            }
        }

        getData()
    },[table])

    const playerDelete =  async (id) => {

        let { data } = await deletePlayer(id)

        data = await data.map(function(val, i) {

            val.image = (<img height="50px" src={val.image}/>)

            val.action = (
                <>
                    <button onClick={() => playerDelete(val.id)}>delete</button>
                    <button className="mLeft5" onClick={() => playerUpdate(val.id)}>update</button>
                </>
            )

            return val
        })

        setPlayersData(data)
    }


    useEffect(() => {
        if (showPlayersForm && updatePlayer) {

            const player = playersData.filter(val => val.id == updatePlayer)[0]
            console.log(player)

            nameRef.current.value       = player.name
            surnameRef.current.value    = player.surname
            positionRef.current.value   = player.pid
            teamRef.current.value       = player.cid
        }
    },[updatePlayer])

    useEffect(() => {
        if (! showPlayersForm) setUpdatePlayer(false)
    }, [showPlayersForm])

    const playerUpdate =  async (id) => {
        setShowPlayersForm(true)
        setUpdatePlayer(id)
    }

    const playersColumns = [
        {
            name: "name",
            label: "Ime",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "surname",
            label: "Prezime",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "prol",
            label: "Pozicija",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "crol",
            label: "Uzrast",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "image",
            label: "Slika",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "action",
            label: "Opcije",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const playersOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
    };

    const addPlayerSubmit = async () => {

        const player = new FormData()

        player.append("image", imageRef.current.files[0])
        player.append("name", nameRef.current.value)
        player.append("surname", surnameRef.current.value)
        player.append("position", positionRef.current.value)
        player.append("category", teamRef.current.value)

        if (updatePlayer) {
            // update

            player.append("id", updatePlayer)

            let { data } = await editPlayer(player)
            console.log(data)

            data = await data.map(function(val, i) {

                val.image = (<img height="50px" src={val.image}/>)

                val.action = (
                    <>
                        <button onClick={() => playerDelete(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => playerUpdate(val.id)}>update</button>
                    </>
                )

                return val
            })

            console.log(data)
            setPlayersData(data)
        } else {
            // add new
            let { data } = await addPlayer(player)

            data = await data.map(function(val, i) {

                val.image = (<img height="50px" src={val.image}/>)

                val.action = (
                    <>
                        <button onClick={() => playerDelete(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => playerUpdate(val.id)}>update</button>
                    </>
                )

                return val
            })

            console.log(data)
            setPlayersData(data)
        }

        console.log(player)
        setShowPlayersForm(false)
        setUpdatePlayer(false)
    }

    return (
        <div className="w80 h100vh backColGold">
            <div className="w100 spaceBetween mTop10">
                <p className="pLeft20 text40 textBolder">Players</p>
                <FontAwesomeIcon
                    onClick={() => setShowPlayersForm(!showPlayersForm)}
                    className="text60 pRight20 cursorPointer"
                    icon={showPlayersForm == true ? faMinusCircle : faPlusCircle}
                />
            </div>

            {showPlayersForm &&
                (
                    <div className="w100 verticalAlignMid mTop40">
                        <div className="w60 pY30 backColBlack">
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Ime</label>
                                    <input ref={nameRef} type="text" className="w100" placeholder="Ukucaj Ime"/>
                                </div>
                                <div className="w48">
                                    <label>Prezime</label>
                                    <input ref={surnameRef} type="text" className="w100" placeholder="Ukucaj prezime"/>
                                </div>
                            </div>
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label className="w100">Pozicija</label>
                                    <select ref={positionRef}>
                                        <option value="">Odaberi poziciju</option>
                                        {
                                            allPositions.map(function(val, i) {
                                                return <option key={i} value={val.id}>{val.role}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="w48">
                                    <label className="w100">Uzrast</label>
                                    <select ref={teamRef}>
                                        <option value="">Odaberi Uzrast</option>
                                        {
                                            allTeams.map(function(val, i) {
                                                return <option key={i} value={val.id}>{val.role}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="w48 colGold">
                                <label>Slika</label>
                                <input ref={imageRef} type="file" accept="image/png, image/gif, image/jpeg" className="w100" placeholder="Izaberi sliku"/>
                            </div>
                            <div className="w100 verticalAlignMid">
                                <button onClick={addPlayerSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="w100 verticalAlignMid mY20">
                <div className="w90 mTop20 verticalAlignMid">
                    <MUIDataTable
                        title={"Players Table"}
                        data={playersData}
                        columns={playersColumns}
                        options={playersOptions}
                        className={"w100"}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminPlayers;