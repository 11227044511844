// import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.css"

import Views from "./Views"
import { useState, useEffect } from "react"
import { showActiveStream } from "./api"

import LiveStream from "./components/LiveStream"

function App() {

    const [lang, setLang]   = useState("RS")
    const [ls, setLs]       = useState(false)

    useEffect(() => {
        localStorage.setItem('lang', lang)
    }, [lang]);

    useEffect(() => {
        async function getData() {
            const { data } = await showActiveStream()
            // console.log(data)

            if (data) {
                setLs(data)
            }
        }

        getData()
    },[])

    return (
        <Router>
            <div className="contentWrapper">

                {ls && <LiveStream data={ls} parentData={lang} parentEvent={setLs}/>}

                <div className="viewsWrapper">
                    <Views parentEvent={setLang} parentData={lang}/>
                </div>
            </div>
        </Router>
    );
}

export default App;
