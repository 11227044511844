import moment from "moment"

function NextGame({ data, logoArray, parentData }) {

    let date;

    if (parentData == "RS") {
        date = moment(data.vreme_odigravanja).format("D. MMMM, YYYY. H:mm[h]")
    } else {
        date = moment(data.vreme_odigravanja).locale("en").format("D. MMMM, YYYY. H:mm[h]")
    }

    date = date == "Invalid date" ? "N / A" : date

    return (
        <div className="upcomingGameWrapperSubb borderRadius12 mXAuto pX10 pY20 mBottom10">
            <div className="verticallyAlignMid homeAwayTeamNameScheduleVs2 textCenter">
                <label className="homeTeamNameSchedule textCenter w50">RK {data?.domacin}</label>
                <label className="awayTeamNameSchedule textCenter w50">RK {data?.gost}</label>
            </div>
            <div className="verticallyAlignMid homeAwayTeamNameScheduleVs">
                <div className="verticalAlignMid imgForTeamNameSchedule">
                    <img src={logoArray[data.domacin]}/>
                </div>
                <p className="text40 pX20 mBottom0 colGold textBolder">VS</p>
                <div className="verticalAlignMid imgForTeamNameSchedule">
                    <img src={logoArray[data.gost]}/>
                </div>
            </div>
            <div className="">
                <p className="text17 textCenter upcomingGameWrapperBottText mBottom0">{date}</p>
                <p className="text13 textCenter upcomingGameWrapperBottText">{data.mesto_odigravanja}</p>
            </div>
        </div>
    )
}

export default NextGame;