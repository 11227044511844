import { useEffect, useState } from "react";

import { getAdminDashboardData } from "../api";

function AdminDashboard({ table }) {

    const [adminData, setAdminData] = useState({
        management  : 0,
        players     : 0,
        news        : 0,
        partners    : 0
    })

    useEffect(() => {
        async function getData() {
            if (table == "dashboard") {
                const { data } = await getAdminDashboardData()
                console.log(data)

                setAdminData({...adminData,
                    management  : data[1],
                    players     : data[2],
                    news        : data[0],
                    partners    : data[3]
                })
            }
        }

        getData()
    }, [table])

    return (
        <div className="w80 h100vh backColGold verticalAlignMid">
            <div className="w80 h100vh backColGold flexWrap">
                <div className="w45 backColBlack mTop20">
                    <div className="w100 h100 pTop20 textCenter displayGrid">
                        <p className="colGold textBolder pTop20 text40 textUppercase">Management</p>
                        <p className="colGold textBolder text80 textUppercase">{adminData.management}</p>
                    </div>
                </div>
                <div className="w45 backColBlack mTop20 mLeft30">
                    <div className="w100 h100 pTop20 textCenter displayGrid">
                        <p className="colGold textBolder pTop20 text40 textUppercase">Players</p>
                        <p className="colGold textBolder text80 textUppercase">{adminData.players}</p>
                    </div>
                </div>
                <div className="w45 backColBlack mTop20 mBottom20">
                    <div className="w100 h100 pTop20 textCenter displayGrid">
                        <p className="colGold textBolder pTop20 text40 textUppercase">News</p>
                        <p className="colGold textBolder text80 textUppercase">{adminData.news}</p>
                    </div>
                </div>
                <div className="w45 backColBlack mTop20 mBottom20 mLeft30">
                    <div className="w100 h100 pTop20 textCenter displayGrid">
                        <p className="colGold textBolder pTop20 text40 textUppercase">Partners</p>
                        <p className="colGold textBolder text80 textUppercase">{adminData.partners}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDashboard;