
function SliderTop({ data, parentData }) {

    // setData({...data,
    //     label           : "2023 / 2024",
    //     headerTop       : "JOMA, španski proizvodjač sportske opreme",
    //     headerBottom    : "Biće novi tehnički sponzor rukometnog kluba Dinamo",
    //     labelBottom     : "Rukometaši i stručni štab Dinama će u predstojećoj sezoni nositi opremu ovog renomiranog brenda",
    //     text            : `
    //         Rukometaši i stručni štab Dinama će u predstojećoj sezoni nositi opremu ovog renomiranog brenda.

    //         JOMA je španska, porodična kompanija, osnovana 1965.godine. Prisutna je u 120 zemalja širom
    //         sveta i posvećena je sportskom tržištu. Skromna i iskrena kompanija koja investira u svoje
    //         proizvode i razvija svoju tehnologiju. JOMA jehrabra kompanija koja se bori da napraduje na
    //         sportskom tržištu. O kvalitetu JOMA proizvoda mogu da sude profesionalni i amaterski sportisti širom sveta.
    //     `,
    // })

    let nameClass = "backColBlackBlured";

    if (
        ! data.label               &&
        ! data.headerTop           &&
        ! data.headerBottom        &&
        ! data.labelBottom         &&
        ! data.text                &&
        ! data.label_eng           &&
        ! data.headerTop_eng       &&
        ! data.headerBottom_eng    &&
        ! data.labelBottom_eng     &&
        ! data.text_eng
    ) nameClass = ""

    return (
        <div className="a111">
            {parentData == "RS" ?
                <div className={"a222 p-1 " + nameClass}>
                    <h6 className="a333">{data.label}</h6>
                    <h1 className="a444">
                        <span className="colGold">{data.headerTop}</span>
                        <br/>{data.headerBottom}
                    </h1>
                    <div className="a554">
                        <h3 className="a555">{data.labelBottom}</h3>
                        <p className="a666">{data.text}</p>
                    </div>
                </div> :
                <div className={"a222 p-1 " + nameClass}>
                    <h6 className="a333">{data.label_eng}</h6>
                    <h1 className="a444">
                        <span className="colGold">{data.headerTop_eng}</span>
                        <br/>{data.headerBottom_eng}
                    </h1>
                    <div className="a554">
                        <h3 className="a555">{data.labelBottom_eng}</h3>
                        <p className="a666">{data.text_eng}</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default SliderTop;
