
import { useEffect, useState } from "react";
import Footer from "../../components/fixed/Footer"
import Navbar from "../../components/fixed/NavBar"
import { showPartners } from "../../api";

function Partners({parentData, parentEvent}) {

    const [partners, setPartners] = useState([])

    useEffect(() => {

        async function getData() {
            const { data } = await showPartners()
            console.log(data)
            setPartners(data)
        }

        getData()
    }, [])

    return (
        <>
            <div className="w100">
                <div id="top_slider_wrapper" className="w100 h300 bg-black">

                    <Navbar parentData={parentData} parentEvent={parentEvent} />

                    <div className="w100 verticalAlignMid pTop200">
                        {parentData == "RS" ? <label className="pageLabel textBolder colGold textUppercase">Partneri</label> :
                        <label className="pageLabel textBolder colGold textUppercase">Partners</label>}
                    </div>
                </div>

                <div className="w100 verticalAlignMid">
                    <div id="partners_wrapper" className="w80 pY30">
                        {partners.map((val, i) => {
                            return (
                                <img key={i} className="partnersImage mX1 mTop20 cursorPointer" onClick={() => window.open(val.url, '_blank')} src={val.image} alt={val.name}/>
                            )
                        })}
                    </div>
                </div>

                <Footer parentData={parentData} />
            </div>
        </>
    )
}

export default Partners;
