import { useEffect, useRef, useState } from "react";

import MUIDataTable from "mui-datatables";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

import {
    addPartner,
    editPartner,
    showPartners,
    deletePartner
} from "../api";

function AdminPartners({ table }) {

    const [partnersData, setPartnersData]           = useState([])
    const [showPartnersForm, setShowPartnersForm]   = useState(false)
    const [updatePartner, setUpdatePartner]         = useState(false)

    const nameRef   = useRef(null)
    const urlRef    = useRef(null)
    const imageRef  = useRef(null)

    const partnersColumns = [
        {
            name: "name",
            label: "Ime",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "url",
            label: "URL",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "image",
            label: "Slika",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "action",
            label: "",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const partnersOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
    };

    useEffect(() => {
        async function getData() {
            if (table == "partners") {

                let { data } = await showPartners()

                data = await data.map(function(val, i) {

                    val.image = (<img height="50px" src={val.image}/>)

                    val.action = (
                        <>
                            <button onClick={() => partnerDelete(val.id)}>delete</button>
                            <button className="mLeft5" onClick={() => partnerUpdate(val.id)}>update</button>
                        </>
                    )

                    return val
                })

                console.log(data)
                setPartnersData(data)
            }
        }

        getData()
    }, [table])

    useEffect(() => {
        if (showPartnersForm && updatePartner) {

            const partner = partnersData.filter(val => val.id == updatePartner)[0]
            console.log(partner)

            nameRef.current.value  = partner.name
            urlRef.current.value   = partner.url
        }
    },[updatePartner])

    const partnerUpdate = async (id) => {
        setShowPartnersForm(true)
        setUpdatePartner(id)
    }

    const partnerDelete = async (id) => {

        let { data } = await deletePartner(id)

        data = await data.map(function(val, i) {

            val.image = (<img height="50px" src={val.image}/>)

            val.action = (
                <>
                    <button onClick={() => partnerDelete(val.id)}>delete</button>
                    <button className="mLeft5" onClick={() => partnerUpdate(val.id)}>update</button>
                </>
            )

            return val
        })

        setPartnersData(data)
    }

    const addPartnerSubmit = async () => {

        const partner = new FormData()

        partner.append("image", imageRef.current.files[0])
        partner.append("name", nameRef.current.value)
        partner.append("url", urlRef.current.value)

        if (updatePartner) {
            // update

            partner.append("id", updatePartner)

            let { data } = await editPartner(partner)
            console.log(data)

            data = await data.map(function(val, i) {

                val.image = (<img height="50px" src={val.image}/>)

                val.action = (
                    <>
                        <button onClick={() => partnerDelete(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => partnerUpdate(val.id)}>update</button>
                    </>
                )

                return val
            })

            console.log(data)
            setPartnersData(data)
        } else {
            // add new
            let { data } = await addPartner(partner)

            data = await data.map(function(val, i) {

                val.image = (<img height="50px" src={val.image}/>)

                val.action = (
                    <>
                        <button onClick={() => partnerDelete(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => partnerUpdate(val.id)}>update</button>
                    </>
                )

                return val
            })

            console.log(data)
            setPartnersData(data)
        }

        console.log(partner)
        setShowPartnersForm(false)
        setUpdatePartner(false)
    }

    return (
        <div className="w80 h100vh backColGold">
            <div className="w100 spaceBetween mTop10">
                <p className="pLeft20 text40 textBolder">Partners</p>
                <FontAwesomeIcon
                    onClick={() => setShowPartnersForm(!showPartnersForm)}
                    className="text60 pRight20 cursorPointer"
                    icon={showPartnersForm == true ? faMinusCircle : faPlusCircle}
                />
            </div>

            {showPartnersForm &&
                (
                    <div className="w100 verticalAlignMid mTop40">
                        <div className="w60 pY30 backColBlack">
                            <div className="w100 spaceBetween colGold">
                                <div className="w32">
                                    <label>Ime</label>
                                    <input ref={nameRef} type="text" className="w100" placeholder="Ukucaj Ime"/>
                                </div>
                                <div className="w32">
                                    <label>URL</label>
                                    <input ref={urlRef} type="text" className="w100" placeholder="Ukucaj url"/>
                                </div>
                                <div className="w32 colGold">
                                    <label>Slika</label>
                                    <input ref={imageRef} type="file" accept="image/png, image/gif, image/jpeg" className="w100" placeholder="Izaberi sliku"/>
                                </div>

                                <button onClick={addPartnerSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="w100 verticalAlignMid mY20">
                <div className="w90 mTop20 verticalAlignMid">
                    <MUIDataTable
                        title={"Partners Table"}
                        data={partnersData}
                        columns={partnersColumns}
                        options={partnersOptions}
                        className={"w100"}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminPartners;