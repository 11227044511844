import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";
import ReactPaginate from 'react-paginate'

import { getNews, getNewsSingleView } from "../../api/index.js"

import NewsCard from "../../components/NewsCard"
import Footer from "../../components/fixed/Footer"
import Navbar from "../../components/fixed/NavBar"
import moment from "moment";

function News({parentData, parentEvent}) {

    const [news, setNews]               = useState([])
    const [singleNews, setSingleNews]    = useState({})
    const [pageNumber, setPageNumber]   = useState(1)
    const [count, setCount]             = useState(0)

    const [searchParams, setSearchParams] = useSearchParams();
    const newsID = searchParams.get("id")

    useEffect(() => {
        async function getData() {

            if (newsID) {
                const { data } = await getNewsSingleView(newsID)
                console.log(data)

                const dateNow   = moment(new Date())
                let duration    = moment.duration(dateNow.diff(data[0].created_at))

                if (parseInt(duration.asMinutes()) < 60) {
                    duration = parseInt(duration.asMinutes()) + ' minutes ago'
                } else if (parseInt(duration.asHours()) < 24) {
                    duration = parseInt(duration.asHours()) + ' hours ago'
                } else {
                    duration = parseInt(duration.asDays()) + ' days ago'
                }

                data[0].duration = duration

                const text      = data[0].text.split('|--@--|')
                const textEng   = data[0].text_eng.split('|--@--|')

                data[0].text       = text
                data[0].text_eng   = textEng

                setSingleNews(data[0])
            }
        }

        getData()
    }, [newsID])

    useEffect(() => {
        async function getData() {
            if (! newsID) {
                const { data } = await getNews(pageNumber)
                setCount(Math.ceil(data[1] / 3))
                setNews(data[0].data)
            }
        }

        getData()
    }, [pageNumber, newsID]);

    const handlePageChange = (selectedObject) => {
		setPageNumber(selectedObject.selected + 1)
	};

    return (
        <>
            <div className="w100">
                <div id="top_slider_wrapper" className="w100 h300 bg-black">

                    <Navbar parentData={parentData} parentEvent={parentEvent} />

                    <div className="w100 verticalAlignMid pTop200">
                        {parentData == "RS" ? <label className="text70 textSpacing20 textBolder colGold textUppercase">Vesti</label> :
                        <label className="text70 textSpacing20 textBolder colGold textUppercase">News</label>}
                    </div>
                </div>

                { !newsID ?
                    <>
                        <div className="container pY30">
                            <div className="col-md-12">
                                <div className="col-md-12 filtr-container" style={{padding: "0px", position: "relative",}}>
                                    <div className="w100 flexWrap newsCardsMainWrapper">

                                        {news.map(function(val, i) {
                                            return <NewsCard key={i} news={val} parentData={parentData} />
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                        { count > 0 && (<div id="news_pagination" className="w100 verticalAlignMid pY30">
                                <div className="w60 verticalAlignMid">
                                    <ReactPaginate
                                        pageCount={count}
                                        pageRange={2}
                                        marginPagesDisplayed={6}
                                        onPageChange={handlePageChange}
                                        containerClassName={'container'}
                                        previousLinkClassName={'page'}
                                        breakClassName={'page'}
                                        nextLinkClassName={'page'}
                                        pageClassName={'page'}
                                        disabledClassName={'disabled'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            </div>)
                        }
                    </>
                :
                    <>
                        <div id="news_single_view_wrapper" className="container pY30 disFlex">
                            <div className="w60 h100 pRight10">
                                <div className="w100 h100">
                                    <div className="w100">
                                        <p className="text30 textBolder">{parentData == "RS" ? singleNews.title : singleNews.title_eng}</p>
                                    </div>
                                    <div className="w100">
                                        <img src={singleNews.image} className="w100" alt="news"/>
                                    </div>
                                    <div className="w100 mTop30">

                                        {parentData == "RS" ?
                                            singleNews.text && singleNews.text.map((val, i) => {
                                                return <p key={i} className="text20 mBottom10">{val}</p>
                                            })
                                        :
                                        singleNews.text_eng && singleNews.text_eng.map((val, i) => {
                                                return <p key={i} className="text20 mBottom10">{val}</p>
                                            })
                                        }
                                        {/* <p className="text20">
                                            {parentData == "RS" ? singleNews.text : singleNews.text_eng}
                                        </p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="w40 h100 pLeft10">
                                {singleNews?.files && singleNews?.files.map((val, i) => {
                                    return (
                                        <div key={i} className="w100 mTop40">
                                            <img src={val} className="" alt="news"/>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="container mY20 spaceBetween pX10">
                            <p className="text25 colGray">{singleNews.name} {singleNews.surname}</p>
                            <p className="text25 colGray">{singleNews.duration}</p>
                        </div>
                    </>
                }

                <Footer parentData={parentData} />
            </div>
        </>
    )
}

export default News;
