import { useEffect, useRef, useState } from "react";

import MUIDataTable from "mui-datatables";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

import {
    showFolders,
    addFile,
    showAllFiles,
    deleteFile
} from "../api";

function AdminFile({ table }) {

    const [fileData, setFileData]           = useState([])
    const [folders, setFolders]             = useState([])
    const [showFileForm, setShowFileForm]   = useState(false)

    const imageRef      = useRef(null)
    const urlRef        = useRef(null)
    const folderRef     = useRef(null)
    const typeRef       = useRef(null)
    const widthRef      = useRef(null)
    const heightRef     = useRef(null)

    const fileColumns = [
        {
            name: "folder",
            label: "Folder",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "image",
            label: "Slika",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "type",
            label: "Type",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "width",
            label: "Sirina",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "height",
            label: "Visina",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "action",
            label: "",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const fileOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
    };

    useEffect(() => {
        async function getData() {
            if (table == "files") {

                let foldersData = await showFolders()
                setFolders(foldersData.data)

                let { data } = await showAllFiles()

                data = await data.map(function(val, i) {

                    val.image = (<img height="50px" src={val.url}/>)

                    val.type = val.type == "0" ? "Slika" : "Video"

                    val.folder = foldersData.data.filter((el) => el.id == val.folder_id)[0].title

                    val.action = (
                        <>
                            <button onClick={() => fileDelete(val.id)}>delete</button>
                        </>
                    )

                    return val
                })

                setFileData(data)
            }
        }

        getData()
    }, [table])

    const fileDelete = async (id) => {

        let { data } = await deleteFile(id)

        data = await data.map(function(val, i) {

            val.image   = (<img height="50px" src={val.url}/>)
            val.type    = val.type == "0" ? "Slika" : "Video"
            val.folder  = folders.filter((el) => el.id == val.folder_id)[0] ? folders.filter((el) => el.id == val.folder_id)[0].title : ""

            val.action = (
                <>
                    <button onClick={() => fileDelete(val.id)}>delete</button>
                </>
            )

            return val
        })

        setFileData(data)
    }

    const addFileSubmit = async () => {

        const file = new FormData()

        file.append("image", imageRef.current.files[0])
        file.append("url", urlRef.current.value)
        file.append("width", widthRef.current.value)
        file.append("height", heightRef.current.value)
        file.append("folder_id", folderRef.current.value)
        file.append("type", typeRef.current.value)

        // add new
        let { data } = await addFile(file)

        data = await data.map(function(val, i) {

            val.image   = (<img height="50px" src={val.url}/>)
            val.type    = val.type == "0" ? "Slika" : "Video"
            val.folder  = folders.filter((el) => el.id == val.folder_id)[0].title

            val.action = (
                <>
                    <button onClick={() => fileDelete(val.id)}>delete</button>
                </>
            )

            return val
        })

        console.log(data)

        setFileData(data)
        setShowFileForm(false)
    }

    return (
        <div className="w80 h100vh backColGold">
            <div className="w100 spaceBetween mTop10">
                <p className="pLeft20 text40 textBolder">Files</p>
                <FontAwesomeIcon
                    onClick={() => setShowFileForm(!showFileForm)}
                    className="text60 pRight20 cursorPointer"
                    icon={showFileForm == true ? faMinusCircle : faPlusCircle}
                />
            </div>

            {showFileForm &&
                (
                    <div className="w100 verticalAlignMid mTop40">
                        <div className="w60 pY30 backColBlack">
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Folder</label>
                                    <select ref={folderRef} className="w100">
                                        <option value="0">Select Folder</option>
                                        {folders && folders.map((val, i) => {
                                            return <option key={i} value={val.id}>{val.title}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="w48 colGold">
                                    <label>Type</label>
                                    <select ref={typeRef} className="w100">
                                        <option value="0">Image</option>
                                        <option value="1">Video</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Width</label>
                                    <input ref={widthRef} type="number" className="w100" placeholder="Ukucaj sirinu"/>
                                </div>
                                <div className="w48">
                                    <label>Height</label>
                                    <input ref={heightRef} type="number" className="w100" placeholder="Ukucaj visinu"/>
                                </div>
                            </div>
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>URL</label>
                                    <input ref={urlRef} type="text" className="w100" placeholder="Ukucaj url"/>
                                </div>
                                <div className="w48 colGold">
                                    <label>Slika</label>
                                    <input ref={imageRef} type="file" accept="image/png, image/gif, image/jpeg" className="w100" placeholder="Izaberi sliku"/>
                                </div>
                            </div>
                            <button className="mTop10" onClick={addFileSubmit}>Submit</button>
                        </div>
                    </div>
                )
            }

            <div className="w100 verticalAlignMid mY20">
                <div className="w90 mTop20 verticalAlignMid">
                    <MUIDataTable
                        title={"Files Table"}
                        data={fileData}
                        columns={fileColumns}
                        options={fileOptions}
                        className={"w100"}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminFile;