
function SliderPartner({ partner }) {
    return (
        <>
            <div className="w200 cursorPointer verticalAlignMid mXAuto pY20">
                <img onClick={() => window.open(partner.url, '_blank')} src={partner.image} alt={partner.name}/>
            </div>
        </>
    )
}

export default SliderPartner;