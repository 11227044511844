import { useEffect, useRef, useState } from "react";

import MUIDataTable from "mui-datatables";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'

import {
    showInternational,
    editInternational
} from "../api";

function AdminInternational({ table }) {

    const [internationalData, setInternationalData]           = useState([])
    const [showInternationalForm, setShowInternationalForm]   = useState(false)
    const [updateInternational, setUpdateInternational]       = useState(false)

    const hostRef       = useRef(null)
    const awayRef       = useRef(null)
    const placeRef      = useRef(null)
    const dateRef       = useRef(null)
    const timeRef       = useRef(null)

    const internationalColumns = [
        {
            name: "host",
            label: "Domacin",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "away",
            label: "Gost",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "place",
            label: "Mesto",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "datetime",
            label: "Datum i vreme",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "action",
            label: "",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const internationalOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
    };

    useEffect(() => {
        async function getData() {
            if (table == "international") {

                let { data } = await showInternational()

                data = await data.map(function(val, i) {

                    val.action = (
                        <>
                            <button onClick={() => internationalUpdate(val.id)}>update</button>
                        </>
                    )

                    return val
                })

                setInternationalData(data)
            }
        }

        getData()
    }, [table])

    useEffect(() => {
        if (showInternationalForm && updateInternational) {

            const international = internationalData.filter(val => val.id == updateInternational)[0]

            const date = international.datetime.split(' ')[0]
            const time = international.datetime.split(' ')[1]

            hostRef.current.value   = international.host
            awayRef.current.value   = international.away
            placeRef.current.value  = international.place
            dateRef.current.value   = date
            timeRef.current.value   = time
        }
    },[updateInternational])

    const internationalUpdate = async (id) => {
        setUpdateInternational(id)
        setShowInternationalForm(true)
    }

    const addInternationalSubmit = async () => {

        const international = new FormData()

        international.append("id", updateInternational)
        international.append("away", awayRef.current.value)
        international.append("host", hostRef.current.value)
        international.append("place", placeRef.current.value)
        international.append("date", dateRef.current.value)
        international.append("time", timeRef.current.value)

        // add new
        let { data } = await editInternational(international)

        data = await data.map(function(val, i) {

            val.action = (
                <>
                    <button onClick={() => internationalUpdate(val.id)}>update</button>
                </>
            )

            return val
        })

        setInternationalData(data)
        setUpdateInternational(false)
        setShowInternationalForm(false)
    }

    return (
        <div className="w80 h100vh backColGold">
            <div className="w100 spaceBetween mTop10">
                <p className="pLeft20 text40 textBolder">Internacionalna Utakmica</p>
                {showInternationalForm == true ?
                    <FontAwesomeIcon
                        onClick={() => {setShowInternationalForm(false); setUpdateInternational(false)}}
                        className="text60 pRight20 cursorPointer"
                        icon={faMinusCircle}
                    /> : ""
                }
            </div>

            {showInternationalForm &&
                (
                    <div className="w100 verticalAlignMid mTop40">
                        <div className="w60 pY30 backColBlack">
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Domacin</label>
                                    <input ref={hostRef} type="text" className="w100" placeholder="Ukucaj domacina"/>
                                </div>
                                <div className="w48">
                                    <label>Gost</label>
                                    <input ref={awayRef} type="text" className="w100" placeholder="Ukucaj gosta"/>
                                </div>
                            </div>
                            <div className="w100 colGold">
                                <label>Mesto</label>
                                <input ref={placeRef} type="text" className="w100" placeholder="Ukucaj mesto"/>
                            </div>
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>Datum</label>
                                    <input ref={dateRef} type="date" className="w100" placeholder="Izaberi datum"/>
                                </div>
                                <div className="w48 colGold">
                                    <label>Vreme</label>
                                    <input ref={timeRef} type="time" className="w100" placeholder="Izaberi vreme"/>
                                </div>
                            </div>
                            <button onClick={addInternationalSubmit}>Submit</button>
                        </div>
                    </div>
                )
            }

            <div className="w100 verticalAlignMid mY20">
                <div className="w90 mTop20 verticalAlignMid">
                    <MUIDataTable
                        title={"International Game Table"}
                        data={internationalData}
                        columns={internationalColumns}
                        options={internationalOptions}
                        className={"w100"}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminInternational;