
function SliderPlayer({ player }) {
    return (
        <div className="w100 cursorPointer">
            <div className="w100 alignCenter">
                <img src={player.image} width="100%"/>
                <p className="pY20 textCenter text20 textUppercase textBolder colGold">{player.name + " " + player.surname}</p>
            </div>
        </div>
    )
}

export default SliderPlayer;