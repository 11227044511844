import { useEffect, useState } from "react"
import ReactPaginate from 'react-paginate'

import { getPlayers } from "../../api/index.js"

import PlayerCard from "../../components/PlayerCard"
import Footer from "../../components/fixed/Footer"
import Navbar from "../../components/fixed/NavBar"

function Players({parentData, parentEvent}) {

    const [players, setPlayers]         = useState([])
    const [pageNumber, setPageNumber]   = useState(1)
    const [count, setCount]             = useState(0)
    const [filter, setFilter]           = useState(1)

    useEffect(() => {
        async function getData() {
            const { data } = await getPlayers({pageNumber, filter})

            setCount(Math.ceil(data[1] / 8))
            setPlayers(data[0].data)
            goToTop()
        }

        getData()
    }, [pageNumber])

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const updateFilter = async (value) => {

        const { data } = await getPlayers({pageNumber : 1, filter : value})

        setCount(data[1] / 8)
        setPlayers(data[0].data)
        setFilter(value)
        setPageNumber(1)
        goToTop()
    }

    const handlePageChange = (selectedObject) => {
		setPageNumber(selectedObject.selected + 1)
	};

    return (
        <>
            <div className="w100">
                <div id="top_slider_wrapper" className="w100 h300 bg-black">

                    <Navbar parentData={parentData} parentEvent={parentEvent} />

                    <div className="w100 verticalAlignMid pTop200">

                        {parentData == "RS" ? <label className="text70 textSpacing20 textBolder colGold textUppercase">Igrači</label> :
                        <label className="text70 textSpacing20 textBolder colGold textUppercase">Players</label>}

                        {/*{parentData == "RS" ? <label className="pageLabel textBolder colGold textUppercase">Igrači</label> :*/}
                        {/*<label className="pageLabel textBolder colGold textUppercase">Players</label>}*/}

                    </div>
                </div>

                <div id="players_content_wrapper">
                    <div id="filter_players" className="w100 verticalAlignMid">
                        <div className="filterPlayersWrapper textCenter pY20">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center">
                                        {parentData == "RS" ?
                                            <ul className="col container-filter portfolioFilte list-unstyled mb-0" id="filter">
                                                <li><a onClick={(() => updateFilter(1))} className={(filter == 1 ? "active textBolder" : "textBolder")}>Prvi Tim</a></li>
                                                <li><a onClick={(() => updateFilter(7))} className={(filter == 7 ? "active textBolder" : "textBolder")}>Stručni štab</a></li>
                                                <li><a onClick={(() => updateFilter(8))} className={(filter == 8 ? "active textBolder" : "textBolder")}>Rukovodstvo</a></li>
                                                <li><a onClick={(() => updateFilter(2))} className={(filter == 2 ? "active textBolder" : "textBolder")}>RA Dinamo</a></li>
                                                <li><a onClick={(() => updateFilter(3))} className={(filter == 3 ? "active textBolder" : "textBolder")}>M16</a></li>
                                                <li><a onClick={(() => updateFilter(4))} className={(filter == 4 ? "active textBolder" : "textBolder")}>M14</a></li>
                                                <li><a onClick={(() => updateFilter(5))} className={(filter == 5 ? "active textBolder" : "textBolder")}>M12</a></li>
                                                <li><a onClick={(() => updateFilter(6))} className={(filter == 6 ? "active textBolder" : "textBolder")}>Mini Rukomet</a></li>
                                            </ul> :
                                            <ul className="col container-filter portfolioFilte list-unstyled mb-0" id="filter">
                                                <li><a onClick={(() => updateFilter(1))} className={(filter == 1 ? "active textBolder" : "textBolder")}>First Team</a></li>
                                                <li><a onClick={(() => updateFilter(7))} className={(filter == 7 ? "active textBolder" : "textBolder")}>Expert Staff</a></li>
                                                <li><a onClick={(() => updateFilter(8))} className={(filter == 8 ? "active textBolder" : "textBolder")}>Management</a></li>
                                                <li><a onClick={(() => updateFilter(2))} className={(filter == 2 ? "active textBolder" : "textBolder")}>RA Dinamo</a></li>
                                                <li><a onClick={(() => updateFilter(3))} className={(filter == 3 ? "active textBolder" : "textBolder")}>M16</a></li>
                                                <li><a onClick={(() => updateFilter(4))} className={(filter == 4 ? "active textBolder" : "textBolder")}>M14</a></li>
                                                <li><a onClick={(() => updateFilter(5))} className={(filter == 5 ? "active textBolder" : "textBolder")}>M12</a></li>
                                                <li><a onClick={(() => updateFilter(6))} className={(filter == 6 ? "active textBolder" : "textBolder")}>Mini Handball</a></li>
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="playersCardMainWrapper">
                        <div className="w100 flexWrap jusContentSpaceNone playersCardMainWrapperSubb">
                            {players.map(function(val, i) {
                                return <PlayerCard key={i} player={val} parentData={parentData} />
                            })}
                        </div>
                    </div>

                    {count > 0 &&
                        (<div id="players_pagination" className="w100 verticalAlignMid pY30">
                            <div className="w60 verticalAlignMid">
                                <ReactPaginate
                                    pageCount={count}
                                    pageRange={2}
                                    marginPagesDisplayed={6}
                                    forcePage={pageNumber - 1}
                                    onPageChange={handlePageChange}
                                    containerClassName={'container'}
                                    previousLinkClassName={'page'}
                                    breakClassName={'page'}
                                    nextLinkClassName={'page'}
                                    pageClassName={'page'}
                                    disabledClassName={'disabled'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>)
                    }
                </div>

                <Footer parentData={parentData} />
            </div>
        </>
    )
}

export default Players;
