import { Link, useLocation } from "react-router-dom"
import { faBars} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ReactFlagsSelect from "react-flags-select"
import { useState, useEffect } from "react"
import axios from "axios"
import moment from "moment"

import { showInternational } from "./../../api/index"

function Navbar({parentData, parentEvent}) {

    const location = useLocation()

    const [nextGame, setNextGame]           = useState({})
    const [international, setInternational] = useState({})

    useEffect(() => {
        axios.get('https://srl.rs/phprest/api/read_utakmice.php?id=41&ekipa=10500')
            .then(response => {
                const date = moment()
                let diff2   = -10000000
                let next    = {}

                response?.data.map((val, i) => {
                    if (val.vreme_odigravanja) {

                        const a = date.diff(moment(val.vreme_odigravanja), 'minutes')

                        if (! isNaN(a) && a < 0 && a > diff2) {
                            diff2 = a
                            next = val
                        }
                    }
                })
                setNextGame(next)
            }).catch(err => console.log(err))
    }, [])

    useEffect(() => {
        async function getData() {
            const { data } = await showInternational()
            console.log(data)

            const date = moment()

            if (data[0].datetime) {
                const a = date.diff(moment(data[0].datetime), 'minutes')

                if (! isNaN(a) && a < 0) {
                    setInternational(data[0])
                }
            }
        }

        getData()
    }, [])

    function onMenuClick () {
	    var navbar = document.getElementById('navigation_bar')
	    var responsive_class_name = 'responsive'
	    navbar.classList.toggle(responsive_class_name)
	}

    return (
        <div id="myTopnav" className="navBarMainWrapper">
            {nextGame?.id &&
                <div className="topMessage backColBlack goldText textAlignCenter w100 verticallyAlignMid my-3">
                    {parentData == "RS" ?
                        (`PREDSTOJEĆA UTAKMICA - ${nextGame.domacin} vs ${nextGame.gost} / ${moment(nextGame.vreme_odigravanja).format("D. MMMM, YYYY. H:mm[h]")} / ${nextGame.mesto_odigravanja}`) :
                        (`NEXT GAME - ${nextGame.domacin} vs ${nextGame.gost} / ${moment(nextGame.vreme_odigravanja).locale("en").format("D. MMMM, YYYY. H:mm[h]")} / ${nextGame.mesto_odigravanja}`)
                    }
                </div>
            }

            {international?.id &&
                <div className="topMessage backColBlack goldText textAlignCenter w100 verticallyAlignMid my-3">
                    {parentData == "RS" ?
                        (`PREDSTOJEĆA INTERNACIONALNA UTAKMICA - ${international.host} vs ${international.away} / ${moment(international.datetime).format("D. MMMM, YYYY. H:mm[h]")} / ${international.place}`) :
                        (`NEXT INTERNATIONAL GAME - ${international.host} vs ${international.away} / ${moment(international.datetime).locale("en").format("D. MMMM, YYYY. H:mm[h]")} / ${international.place}`)
                    }
                </div>
            }

        	<div className={`verticallyAlignMid navBarAndLogo ${!international && !nextGame && "pTop56"}`}>
	            <div id="main_menu_logo" className="aForLogo verticallyAlignMid">
	                <img src="images/rkd2.png" title="logo"/>
	            </div>
	            <div id="navigation_bar" className="navBarNew verticallyAlignMid mLeftAuto mRight12">
                            {/*<div id="main_menu_logo2" className="aForLogo2 verticallyAlignMid">
                                <img src="fixed/components/images/joma-logo-svg-vector.svg" title="logo"/>
                            </div>*/}
                    {parentData == "RS" ?
                        <>
                            <div className={(location.pathname == "/" ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center")}>
                                <Link to="/" className="w100">
                                    Početna
                                </Link>
                            </div>
                            <div className={((location.pathname == "/news" || location.pathname == "/vesti") ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center dropDownLinks")}>
                                <Link to="/vesti" className="w100">
                                    Vesti
                                </Link>
                            </div>
                            <div className={((location.pathname == "/igraci" || location.pathname == "/players") ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center dropDownLinks")}>
                                <Link to="/igraci" className="w100">
                                    Igrači
                                </Link>
                            </div>
                            <div className={((location.pathname == "/raspored" || location.pathname == "/schedule") ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center dropDownLinks")}>
                                <Link to="/raspored" className="w100">
                                    Raspored
                                </Link>
                            </div>
                            <div className={((location.pathname == "/galerija" || location.pathname == "/gallery") ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center dropDownLinks")}>
                                <Link to="/galerija" className="w100">
                                    Galerija
                                </Link>
                            </div>
                            <div className={((location.pathname == "/partneri" || location.pathname == "/partners") ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center dropDownLinks")}>
                                <Link to="/partneri" className="w100">
                                    Partneri
                                </Link>
                            </div>
                            <div className={((location.pathname == "/kontakt" || location.pathname == "/contact") ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center dropDownLinks")}>
                                <Link to="/kontakt" className="w100">
                                    Kontakt
                                </Link>
                            </div>
                        </> :
                        <>
                            <div className={(location.pathname == "/" ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center dropDownLinks")}>
                                <Link to="/" className="w100">
                                    Home
                                </Link>
                            </div>
                            <div className={((location.pathname == "/news" || location.pathname == "/vesti") ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center dropDownLinks")}>
                                <Link to="/news" className="w100">
                                    News
                                </Link>
                            </div>
                            <div className={((location.pathname == "/players" || location.pathname == "/igraci" ) ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center dropDownLinks")}>
                                <Link to="/players" className="w100">
                                    Players
                                </Link>
                            </div>
                            <div className={((location.pathname == "/schedule" || location.pathname == "/raspored" ) ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center dropDownLinks")}>
                                <Link to="/schedule" className="w100">
                                    Schedule
                                </Link>
                            </div>
                            <div className={((location.pathname == "/galerija" || location.pathname == "/gallery") ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center dropDownLinks")}>
                                <Link to="/gallery" className="w100">
                                    Gallery
                                </Link>
                            </div>
                            <div className={((location.pathname == "/partners" || location.pathname == "/partneri" ) ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center dropDownLinks")}>
                                <Link to="/partners" className="w100">
                                    Partners
                                </Link>
                            </div>
                            <div className={((location.pathname == "/contact" || location.pathname == "/kontakt" ) ? "menuLinkActive w-100" : "verticallyAlignMid w-100 text-center dropDownLinks")}>
                                <Link to="/contact" className="w100">
                                    Contact
                                </Link>
                            </div>
                        </>
                    }


	            </div>
                <ReactFlagsSelect
                    countries={["GB", "RS"]}
                    customLabels={{GB: "English", RS: "Srpski"}}
                    selected={parentData}
                    onSelect={(lang) => parentEvent(lang)}
                    className=""
                />
	            <div onClick={() => onMenuClick()} id="menu-icon" className="menuIcon mLeft10 mRight12">
	                <FontAwesomeIcon icon={faBars} size="xl" className="colGold" />
	            </div>
	        </div>
        </div>
    )
}

export default Navbar;
