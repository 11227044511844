import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Home from "./pages/Home/Home.js"
import News from "./pages/News/News.js"
import Players from "./pages/Players/Players.js";
import Partners from "./pages/Partners/Partners.js";
import Games from "./pages/Games/Games.js";
import Schedule from "./pages/Schedule/Schedule.js";
import Gallery from "./pages/Gallery/Gallery.js";
import Contact from "./pages/Contact/Contact.js";
import Admin from "./pages/Admin/Admin.js";
import { getJournalists } from "./api/index.js";

const Views = ({parentData, parentEvent}) => {

    const [journalists, setJournalists]             = useState([])
    const [awaitJournalists, setAwaitJournalists]   = useState(false)

    const location = useLocation()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            // behavior: 'smooth',
        });
    }, [location])

    useEffect(() => {
        async function getData() {
            const { data } = await getJournalists()
            console.log(data)
            setJournalists(data)
            setAwaitJournalists(true)
        }

        getData()
    }, [])

    return (
        <>
            <Routes>
                <Route path="/" element={<Home parentData={parentData} parentEvent={parentEvent} />} />

                <Route path="/vesti" element={<News parentData={parentData} parentEvent={parentEvent}/>} />
                <Route path="/news" element={<News parentData={parentData} parentEvent={parentEvent}/>} />

                <Route path="/igraci" element={<Players parentData={parentData} parentEvent={parentEvent}/>} />
                <Route path="/players" element={<Players parentData={parentData} parentEvent={parentEvent}/>} />

                <Route path="/partneri" element={<Partners parentData={parentData} parentEvent={parentEvent}/>} />
                <Route path="/partners" element={<Partners parentData={parentData} parentEvent={parentEvent}/>} />

                <Route path="/utakmice" element={<Games parentData={parentData} parentEvent={parentEvent}/>} />
                <Route path="/games" element={<Games parentData={parentData} parentEvent={parentEvent}/>} />

                <Route path="/raspored" element={<Schedule parentData={parentData} parentEvent={parentEvent}/>} />
                <Route path="/schedule" element={<Schedule parentData={parentData} parentEvent={parentEvent}/>} />

                <Route path="/galerija" element={<Gallery parentData={parentData} parentEvent={parentEvent}/>} />
                <Route path="/gallery" element={<Gallery parentData={parentData} parentEvent={parentEvent}/>} />

                <Route path="/kontakt" element={<Contact parentData={parentData} parentEvent={parentEvent}/>} />
                <Route path="/contact" element={<Contact parentData={parentData} parentEvent={parentEvent}/>} />

                <Route path="/admin" element={<Admin/>} />

                {journalists.length > 0 && journalists.map((val, i) => (
                    <Route key={i} path={'/' + val.url} element={<Admin id={val.id}/>}/>
                ))}

                {awaitJournalists &&
                    <Route
                        path="*"
                        element={<Navigate to="/" replace={true} />}
                    />
                }
            </Routes>
        </>
    );
};

export default Views;
