import { useEffect, useRef, useState } from "react";

import MUIDataTable from "mui-datatables";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

import {
    addLiveStream,
    showLiveStream,
    deleteLiveStream
} from "../api";

function AdminLiveStream({ table }) {

    const [liveStreamData, setLiveStreamData]           = useState([])
    const [showLiveStreamForm, setShowLiveStreamForm]   = useState(false)

    const titleRef      = useRef(null)
    const titleEngRef   = useRef(null)
    const urlRef        = useRef(null)
    const dateRef       = useRef(null)

    const liveStreamColumns = [
        {
            name: "title",
            label: "Naslov",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "title_eng",
            label: "Naslov Eng",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "url",
            label: "URL",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "date",
            label: "Datum",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "action",
            label: "",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const liveStreamOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
    };

    useEffect(() => {
        async function getData() {
            if (table == "liveStream") {

                let { data } = await showLiveStream()

                data = await data.map(function(val, i) {

                    val.action = (
                        <>
                            <button onClick={() => liveStreamDelete(val.id)}>delete</button>
                        </>
                    )

                    return val
                })

                console.log(data)
                setLiveStreamData(data)
            }
        }

        getData()
    }, [table])

    const liveStreamDelete = async (id) => {

        let { data } = await deleteLiveStream(id)

        data = await data.map(function(val, i) {

            val.action = (
                <>
                    <button onClick={() => liveStreamDelete(val.id)}>delete</button>
                </>
            )

            return val
        })

        setLiveStreamData(data)
    }

    const addLiveStreamSubmit = async () => {

        const liveStream = new FormData()

        liveStream.append("title", titleRef.current.value)
        liveStream.append("titleEng", titleEngRef.current.value)
        liveStream.append("url", urlRef.current.value)
        liveStream.append("date", dateRef.current.value)

        // add new
        let { data } = await addLiveStream(liveStream)

        data = await data.map(function(val, i) {

            val.action = (
                <>
                    <button onClick={() => liveStreamDelete(val.id)}>delete</button>
                </>
            )

            return val
        })

        console.log(data)
        setLiveStreamData(data)

        console.log(liveStream)
        setShowLiveStreamForm(false)
    }

    return (
        <div className="w80 h100vh backColGold">
            <div className="w100 spaceBetween mTop10">
                <p className="pLeft20 text40 textBolder">Uzivo prenos</p>
                <FontAwesomeIcon
                    onClick={() => setShowLiveStreamForm(!showLiveStreamForm)}
                    className="text60 pRight20 cursorPointer"
                    icon={showLiveStreamForm == true ? faMinusCircle : faPlusCircle}
                />
            </div>

            {showLiveStreamForm &&
                (
                    <div className="w100 verticalAlignMid mTop40">
                        <div className="w60 pY30 backColBlack">
                            <div className="w100 spaceBetween colGold">
                                <div className="w45">
                                    <label>Naslov</label>
                                    <input ref={titleRef} type="text" className="w100" placeholder="Ukucaj naslov"/>
                                </div>
                                <div className="w45">
                                    <label>Naslov Eng</label>
                                    <input ref={titleEngRef} type="text" className="w100" placeholder="Ukucaj naslov na engleskom"/>
                                </div>
                            </div>
                            <div className="w100 spaceBetween colGold">
                                <div className="w45">
                                    <label>URL</label>
                                    <input ref={urlRef} type="text" className="w100" placeholder="Ukucaj url"/>
                                </div>
                                <div className="w45 colGold">
                                    <label>Izaberi datum</label>
                                    <input ref={dateRef} type="date" className="w100" placeholder="Izaberi datum"/>
                                </div>
                            </div>
                            <button onClick={addLiveStreamSubmit}>Submit</button>
                        </div>
                    </div>
                )
            }

            <div className="w100 verticalAlignMid mY20">
                <div className="w90 mTop20 verticalAlignMid">
                    <MUIDataTable
                        title={"Live Stream Table"}
                        data={liveStreamData}
                        columns={liveStreamColumns}
                        options={liveStreamOptions}
                        className={"w100"}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminLiveStream;