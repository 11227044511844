import moment from "moment"

function PrevGame({ data, logoArray, parentData }) {

    return (
        <div className="w100 borderBotomGray1">
            <div className="pY10">
                <div className="fakeNamesLastResult verticallyAlignMid">
                    <label className="fontSegoe textBolder pRight5 col-6">RK {data.domacin}</label>
                    <label className="fontSegoe textBolder pLeft5 col-6">RK {data.gost}</label>
                </div>

                <div className="verticalAlignMid mainforLastGameResults">
                    <div className="lastReultHomeTeam verticalAlignEndMid col-5">
                        <label className="fontSegoe textBolder trueNameResults pRight5">RK {data.domacin}</label>
                        <div className="imgLastReultHomeTeam">
                            <img src={logoArray[data.domacin]}/>
                        </div>
                    </div>
                    <div className="lastReultScore pX10 col-2 verticalAlignMid">
                        <p className="font20 textBolder textMid mBottom0">{data.ekipa1_rez_kraj} - {data.ekipa2_rez_kraj}</p>
                        <p className="font13 textBolder textMid">&#40;{data.ekipa1_rez_poluvreme} - {data.ekipa2_rez_poluvreme}&#41;</p>
                    </div>
                    <div className="lastReultGuestTeam verticalAlignFlexStart col-5">
                        <div className="imgLastReultGuestTeam">
                            <img src={logoArray[data.gost]}/>
                        </div>
                        <label className="fontSegoe textBolder trueNameResults pLeft5">RK {data.gost}</label>
                    </div>
                </div>

                <div className="verticalAlignMid mainforLastGameResults2">
                    <div className="lastReultHomeTeam verticalAlignMid">
                        <label className="fontSegoe textBolder trueNameResults pRight5">RK {data.domacin}</label>
                        <div className="imgLastReultHomeTeam">
                            <img src={logoArray[data.domacin]}/>
                        </div>
                    </div>
                    <div className="lastReultScore pX10">
                        <p className="font20 textBolder textMid mBottom0">{data.ekipa1_rez_kraj} - {data.ekipa2_rez_kraj}</p>
                        <p className="font13 textBolder textMid">&#40;{data.ekipa1_rez_poluvreme} - {data.ekipa2_rez_poluvreme}&#41;</p>
                    </div>
                    <div className="lastReultGuestTeam verticalAlignMid">
                        <div className="imgLastReultGuestTeam">
                            <img src={logoArray[data.gost]}/>
                        </div>
                        <label className="fontSegoe textBolder trueNameResults pLeft5">RK {data.gost}</label>
                    </div>
                </div>



                <div className="pTop10 textCenter text13">
                    {parentData == "RS" ?
                        <p>{moment(data.vreme_odigravanja).format("D. MMMM, YYYY")} / {data.mesto_odigravanja}</p>
                        : <p>{moment(data.vreme_odigravanja).locale("en").format("D. MMMM, YYYY")} / {data.mesto_odigravanja}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default PrevGame;