
function LeagueCard({ data, nameClass }) {

    // console.log(data)

    return (
        <a href={data.url} target="_blank">
            <div className={`h100 topNewsBlock topNewsBlock1 h250 ${nameClass}`} style={{backgroundImage : `url(${data.image})`}}>
                <div className="textRight"></div>
            </div>
        </a>
    )
}

export default LeagueCard;