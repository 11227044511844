import { useEffect, useState } from "react"
import axios from "axios"
import moment from "moment"
import 'moment/locale/sr'

import { Swiper, SwiperSlide } from "swiper/react"
import Footer from "./../../components/fixed/Footer"
import Navbar from "./../../components/fixed/NavBar"
import NextGame from "./../../components/NextGame"
import PrevGame from "../../components/PrevGame"
import SliderNews from "../../components/slider/SliderNews"
import SliderPlayer from "../../components/slider/SliderPlayer"
import LeagueCard from "../../components/LeagueCard"

import { InstagramEmbed } from 'react-social-media-embed'

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/pagination"
import "swiper/css/navigation"

import { Pagination, Autoplay, EffectFade, Navigation } from "swiper"
import SliderPartner from "../../components/slider/SliderPartner"
import LeagueTable from "../../components/LeagueTable"
import SliderTop from "../../components/slider/SliderTop"
import { getHomeData } from "../../api"
import { Link, useNavigate } from "react-router-dom"

function Home({parentData, parentEvent}) {

    const navigate = useNavigate()

    const [tableData, setTableData] = useState([])
    const [lastGame, setLastGame]   = useState({})
    const [lastGames, setLastGames] = useState([])
    const [nextGame, setNextGame]   = useState({})
    const [nextGames, setNextGames] = useState([])

    const [indexData, setIndexData] = useState({
        firstLineup : [],
        news        : [],
        partners    : [],
        posts       : [],
        topSlider   : [],
        leagues     : [],
    })

    const logoArray = {
        "Dinamo"                    : "images/rkd2.png",
        "Vojvodina"                 : "images/Teams/vojvodinans.jpg",
        "Proleter"                  : "images/Teams/proleter_zrenjanin.png",
        "Crvena zvezda Primobet"    : "images/Teams/crvena_zvezda.png",
        "Dubočica 54"               : "images/Teams/dubocica_leskovac.png",
        "Radnički"                  : "images/Teams/radnicki_kragujevac.png",
        "Jugović"                   : "images/Teams/jugovic_kac.png",
        "Partizan"                  : "images/Teams/partizan.png",
        "Vranje 1957"               : "images/Teams/vranje.png",
        "Ub"                        : "images/Teams/rkub.jpg",
        "Obilić"                    : "images/Teams/obilic_beograd.png",
        "Metaloplastika Elixir"     : "images/Teams/meteloplastika.png"
    }

    useEffect(() => {
        axios.get('https://srl.rs/phprest/api/read.php?id=41')
            .then(response => {
                // console.log(response?.data ?? [])
                setTableData(response?.data ?? [])
            }).catch(err => console.log(err));
    }, [])

    useEffect(() => {
        axios.get('https://srl.rs/phprest/api/read_utakmice.php?id=41&ekipa=10500')
            .then(response => {
                // console.log(response?.data ?? [])
                // setGamesData(response?.data ?? [])

                const date = moment() // YYYY-MM-DD HH:mm:ss

                let diff    = 10000000
                let diff2   = -10000000

                let last    = {}
                let next    = {}

                let nextArray = []
                let prevArray = []

                response?.data.map((val, i) => { // 2023-09-13 18:00:00
                    if (val.vreme_odigravanja) {

                        const a = date.diff(moment(val.vreme_odigravanja), 'minutes')

                        if (! isNaN(a) && a > 0 && a < diff) {
                            diff = a
                            last = val
                        }

                        if (! isNaN(a) && a < 0 && a > diff2) {
                            diff2 = a
                            next = val
                        }

                        if (a > 0) {
                            prevArray.push(val)
                        } else {
                            nextArray.push(val)
                        }
                    } else {
                        nextArray.push(val)
                    }
                })

                setLastGame(last)
                setLastGames(prevArray)
                setNextGame(next)
                setNextGames(nextArray)
            }).catch(err => console.log(err));
    }, [])

    useEffect(() => {
        async function getData() {
            const { data } = await getHomeData()

            console.log(data)
            // setIndexData(data)
            setIndexData({...indexData,
                firstLineup : data.firstLineup,
                news        : data.news,
                partners    : data.partners,
                posts       : data.posts,
                topSlider   : data.topSlider,
                leagues     : data.leagues
            })
        }

        getData()
    }, []);

   	return (
        <>
            <div className="w100">
                <div id="top_slider_wrapper" className="w100 vh100 bg-black">
                {/* <div id="top_slider_wrapper" className="w100 h800 bg-black"> */}

                    <Navbar parentData={parentData} parentEvent={parentEvent} />

                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 40000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        effect={"fade"}
                        modules={[Autoplay, Pagination, EffectFade]}
                        className="mySwiper"
                    >

                        {indexData.topSlider.map(function(val, i) {
                            return (
                                <SwiperSlide key={i} style={{backgroundImage : `url(${val.image})`}} className={`slider ${i == 0 ? "sliderFirst" : ""}`}>
                                    <SliderTop data={val} parentData={parentData}/>
                                </SwiperSlide>
                            )
                        })}

                        {/* {<SwiperSlide style={{backgroundImage : "url('/images/bannerTop1.jpg')"}} className="slider sliderFirst">
                            <SliderTop slider="first" />
                        </SwiperSlide>
                        <SwiperSlide style={{backgroundImage : "url('https://www.rkdinamo.rs/images/bg.jpg')"}} className="slider">
                            <SliderTop slider="second" />
                        </SwiperSlide>
                        <SwiperSlide style={{backgroundImage : "url('/images/hala-sportoiva2.jpg')"}} className="slider">
                            <SliderTop slider="third" />
                        </SwiperSlide>} */}
                    </Swiper>
                </div>
                <div id="last_game_wrapper" className="pTop150 pBottom100">
                    {lastGame?.id &&
                        <>
                            <div id="a123" className="verticalAlignMid">
                                <div id="a1" className="textCenter">
                                    <label className="fontSegoe colWhite clubNames maxWidth300">RK {lastGame.domacin}</label>
                                    <div className="logoImagesLastGame verticallyAlignMid">
                                        <img src={logoArray[lastGame.domacin]}/>
                                    </div>
                                </div>
                                <div id="a2" className="mX20 lastScoreDateAndPlaceMain1">
                                    <p className="fontSegoe scoreLastGame colGold textCenter mBottom0">{lastGame.ekipa1_rez_kraj} - {lastGame.ekipa2_rez_kraj}</p>
                                    <p className="fontSegoe font20 colGold textCenter mBottom10">&#40;{lastGame.ekipa1_rez_poluvreme} - {lastGame.ekipa2_rez_poluvreme}&#41;</p>
                                    {parentData == "RS" ?
                                        <p className="fontSegoe colWhite textCenter lastGameDate">{moment(lastGame.vreme_odigravanja).format("D. MMMM, YYYY")}</p>
                                        : <p className="fontSegoe colWhite textCenter lastGameDate">{moment(lastGame.vreme_odigravanja).locale("en").format("D. MMMM, YYYY")}</p>
                                    }
                                    <p className="fontSegoe colWhite textCenter lastGameDate">{lastGame.mesto_odigravanja}</p>
                                    {/* <button id="last_game_btn" className="pX20 backColGold textCenter mXAuto itemsCenter cursorPointer">
                                        <p>See more</p>
                                    </button> */}
                                </div>
                                <div className="mX20 lastScoreDateAndPlaceMain3">
                                    <p className="fontSegoe scoreLastGame colWhite textCenter mBottom10">VS</p>
                                </div>
                                <div id="a3" className="textCenter">
                                    <label className="fontSegoe colWhite clubNames maxWidth300">RK {lastGame.gost}</label>
                                    <div className="logoImagesLastGame verticallyAlignMid">
                                        <img src={logoArray[lastGame.gost]}/>
                                    </div>
                                </div>
                            </div>
                            <div className="lastScoreDateAndPlaceMain2 mXAuto">
                                <p className="fontSegoe scoreLastGame colGold textCenter mBottom0">{lastGame.ekipa1_rez_kraj} - {lastGame.ekipa2_rez_kraj}</p>
                                <p className="fontSegoe font17 colGold textCenter mBottom10">&#40;{lastGame.ekipa1_rez_poluvreme} - {lastGame.ekipa2_rez_poluvreme}&#41;</p>
                                {parentData == "RS" ?
                                    <p className="fontSegoe colWhite textCenter lastGameDate">{moment(lastGame.vreme_odigravanja).format("D. MMMM, YYYY")}</p>
                                    : <p className="fontSegoe colWhite textCenter lastGameDate">{moment(lastGame.vreme_odigravanja).locale("en").format("D. MMMM, YYYY")}</p>
                                }
                                <p className="fontSegoe colWhite textCenter lastGameDate">{lastGame.mesto_odigravanja}</p>
                                {/* <button id="last_game_btn" className="pX20 backColGold textCenter mXAuto itemsCenter cursorPointer">
                                    <p>See more</p>
                                </button>*/}
                            </div>
                        </>
                    }
                </div>
                <div id="arcus_and_cup_logo" className="w100 itemsCenter">
                    <div id="" className="logoElementsHomePage zIndex10">
                        <div id="" className="w100 verticalAlignMid logoElementsHomePageSubb">
                        	<div className="verticalAlignMid logoElementsHomePageSubb1">

                                {indexData.leagues.map(function(val, i) {

                                    if (i > 1) return

                                    return (
                                        <LeagueCard key={i} data={val} nameClass={""}/>
                                    )
                                })}

                                {/* <a href="https://srl.rs/" target="_blank">
                                    <div className="h100 topNewsBlock topNewsBlock1 h250">
                                        <div className="textRight"></div>
                                    </div>
                                </a>
                                <a href="https://rss.org.rs/" target="_blank">
                                    <div className="h100 topNewsBlock topNewsBlock2 h250">
                                        <div className="textCenter">
                                        </div>
                                    </div>
                                </a> */}
	                        </div>
                            {indexData.leagues.map(function(val, i) {

                                if (i < 2) return

                                return (
                                    <LeagueCard key={i} data={val} nameClass={"verticalAlignMid"}/>
                                )
                            })}
                            {/* <a href="https://eurohandball.com/" target="_blank">
                                <div className="h100 topNewsBlock topNewsBlock3 h250">
                                    <div className="">
                                    </div>
                                </div>
                            </a> */}
                        </div>
                    </div>
                </div>
                <div id="games_and_statistics_wrapper" className="pTop100">
                    { nextGame?.id &&
                        <div className="">
                            <div className="verticallyAlignMid">
                                <div className="verticalAlignMid imagesForResults">
                                    <img src={logoArray[nextGame.domacin]}/>
                                </div>
                                <p className="text40 pX20 gameAnnouncementTextVs">VS</p>
                                <div className="verticalAlignMid imagesForResults">
                                    <img src={logoArray[nextGame.gost]}/>
                                </div>
                            </div>
                            <div className="w100 verticalAlignMid mTop10">
                                {parentData == "RS" ?
                                    <p className="gameAnnouncementText textCenter">{moment(nextGame.vreme_odigravanja).format("D. MMMM, YYYY. H:mm[h]")} / {nextGame.mesto_odigravanja}</p>
                                    : <p className="gameAnnouncementText textCenter">{moment(nextGame.vreme_odigravanja).locale("en").format("D. MMMM, YYYY. H:mm[h]")} / {nextGame.mesto_odigravanja}</p>
                                }
                            </div>
                        </div>
                    }
                    <div id="upcoming_games_wrapper" className="upcomingGameWrapper w100 pTop50 pBott100">
                        {
                            nextGames.map((val, i) => {
                                if (val.id && [1, 2, 3].includes(i)) {
                                    return <NextGame key={i} data={val} logoArray={logoArray} parentData={parentData}/>
                                }
                            })
                        }
                            {parentData == "RS" ?
                                <div className="backColBlack borderRadius12 verticalAlignMid cursorPointer mTop40 seeSchedule" onClick={() => navigate('/schedule?id=next')}>
                                    <p className="colGold text20 pTop10 textUppercase">Pogledaj raspored</p>
                                </div> :
                                <div className="backColBlack borderRadius12 verticalAlignMid cursorPointer mTop40 seeSchedule" onClick={() => navigate('/schedule?id=next')}>
                                    <p className="colGold text20 pTop10 textUppercase">See Schedule</p>
                                </div>
                            }
                    </div>
                    <div className="lastResultsWrapper">
                        <div className="w100">
                            {parentData == "RS" ? <p className="textUppercase lastResultsHead textCenter text20 pY20 backColGold textBolder">Poslednji rezultati</p> :
                            <p className="textUppercase textCenter text20 lastResultsHead pY20 backColGold textBolder">The last results</p>}
                        </div>
                        <div id="last_results_wrapper" className="w100">

                            {
                                lastGames.map((val, i) => {
                                    if (val.id && [0, 1, 2].includes(i)) {
                                        return <PrevGame key={i} data={val} logoArray={logoArray} parentData={parentData}/>
                                    }
                                })
                            }

                            <div className="w100 textCenter mTop20">
                                {parentData == "RS" ?
                                    <a id="see_all_results" onClick={() => navigate('/schedule?id=prev')}>Pogledaj sve rezultate -{">"}</a> :
                                    <a id="see_all_results" onClick={() => navigate('/schedule?id=prev')}>See all results -{">"}</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div id="top_news_wrapper" className="w100 mTop50 pY50">
                    <div className="w100 alignCenter textCenter">
                        {parentData == "RS" ? <p className="text60 textBolder textUppercase colWhite mBottom0">Top vesti</p> :
                        <p className="text60 textBolder textUppercase colWhite mBottom0">Top news</p>}
                        {parentData == "RS" ? <p className="text15 textBolder textUppercase colGold cursorPointer">Pogledaj sve vesti</p> :
                        <p className="text15 textBolder textUppercase colGold cursorPointer">See all news</p>}
                    </div>
                    <div className="w100 verticalAlignMid mTop50">
                        <div className="w80">
                            <Swiper
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                slidesPerView={4}
                                spaceBetween={10}
                                breakpoints={{
                                    150: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 65,
                                    },
                                    1388: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    1920: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                }}
                                modules={[Autoplay]}
                                className="mySwiper2"
                            >
                                {indexData.news.map(function(val, i) {
                                    return (
                                        <SwiperSlide key={i}>
                                            <SliderNews news={val} className="h100" parentData={parentData}/>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
                <div id="league_standings_wrapper" className="w100 minHeight800">
                    <LeagueTable parentData={parentData} data={tableData} />
                </div>
                <div id="top_players_wrapper" className="w100 minHeight600 alignCenter pY30">
                    <div className="w100 verticalAlignMid">
                        <div id="see_all_players" className="pY30 w80 spaceBetween">
                            <div className="pTop50">
                                {parentData == "RS" ? <p className="text30 textBolder textUppercase colWhite">Prvi tim 2023/24</p> :
                                <p className="text30 textBolder textUppercase colWhite">First Team 2023/24</p>}
                                { parentData == "RS" ?
                                    <Link to="/igraci">
                                        <p className="text17 textBolder textUppercase colGold cursorPointer">Pogledaj sve igrače</p>
                                    </Link> :
                                    <Link to="/players">
                                        <p className="text17 textBolder textUppercase colGold cursorPointer">See all players</p>
                                    </Link>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="w100 verticalAlignMid">
                        <div className="w80">
                            <Swiper
                                autoplay={{
                                    delay: 3500,
                                    disableOnInteraction: false,
                                }}
                                slidesPerView={4}
                                spaceBetween={10}
                                // navigation={true}
                                breakpoints={{
                                    150: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    300: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    580: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                    1388: {
                                        slidesPerView: 4,
                                        spaceBetween: 50,
                                    },
                                }}
                                modules={[Autoplay, Navigation]}
                                className="mySwiper3"
                            >
                                {indexData.firstLineup.map(function(val, i) {
                                    return (
                                        <SwiperSlide key={i}>
                                            <SliderPlayer player={val}/>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
                <div className="w100 verticalAlignMid">
                    <div className="w80">
                        <Swiper
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            slidesPerView={6}
                            spaceBetween={10}
                            // navigation={true}
                            breakpoints={{
                                150: {
                                    slidesPerView: 1,
                                    spaceBetween: 80,
                                },
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 80,
                                },
                                615: {
                                    slidesPerView: 1,
                                    spaceBetween: 80,
                                },
                                720: {
                                    slidesPerView: 2,
                                    spaceBetween: 80,
                                },
                                888: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                                1224: {
                                    slidesPerView: 4,
                                    spaceBetween: 50,
                                },
                                1498: {
                                    slidesPerView: 5,
                                    spaceBetween: 50,
                                },
                                1700: {
                                    slidesPerView: 6,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Autoplay, Navigation]}
                            className="mySwiper3"
                        >
                            {indexData.partners.map(function(val, i) {
                                return (
                                    <SwiperSlide className="verticalAlignMid" key={i}>
                                        <SliderPartner partner={val}/>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>

                <div className="w100 verticalAlignMid pY20 backImgGoldBlack">
                    <div className="w80">
                        <Swiper
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            slidesPerView={3}
                            spaceBetween={30}
                            // navigation={true}
                            breakpoints={{
                                150: {
                                    slidesPerView: 1,
                                    spaceBetween: 80,
                                },
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 80,
                                },
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 80,
                                },
                                888: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 50,
                                },
                                1338: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                                1700: {
                                    slidesPerView: 4,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Autoplay, Navigation]}
                            className="mySwiper4"
                        >
                            {indexData.posts.map(function(val, i) {
                                return (
                                    <SwiperSlide className="verticalAlignMid" key={i}>
                                        <InstagramEmbed
                                            url={val.url}
                                            height="800px"
                                        />
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
            <Footer parentData={parentData} />
        </>
   	);
}

export default Home;
