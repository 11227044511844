import { useEffect, useRef, useState } from "react";

import MUIDataTable from "mui-datatables";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

import {
    addLeagues,
    showAllLeagues,
    editLeagues,
    deleteLeagues
} from "../api";

function AdminLeagues({ table }) {

    const [leaguesData, setLeaguesData]             = useState([])
    const [showLeaguesForm, setShowLeaguesForm]     = useState(false)
    const [updateLeagues, setUpdateLeagues]         = useState(false)

    const imageRef      = useRef(null)
    const urlRef        = useRef(null)

    const leaguesColumns = [
        {
            name: "url",
            label: "URL",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "image",
            label: "Slika",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "action",
            label: "",
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const leaguesOptions = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 10,
        viewColumns: true,
        selectableRows: "none",
    };

    useEffect(() => {
        async function getData() {
            if (table == "leagues") {

                let { data } = await showAllLeagues()

                data = await data.map(function(val, i) {

                    val.image = (<img height="50px" src={val.image}/>)

                    val.action = (
                        <>
                            <button onClick={() => leaguesDelete(val.id)}>delete</button>
                            <button className="mLeft5" onClick={() => leaguesUpdate(val.id)}>update</button>
                        </>
                    )

                    return val
                })

                console.log(data)
                setLeaguesData(data)
            }
        }

        getData()
    }, [table])

    useEffect(() => {
        if (showLeaguesForm && updateLeagues) {

            const league = leaguesData.filter(val => val.id == updateLeagues)[0]
            console.log(league)

            urlRef.current.value = league.url
        }
    },[updateLeagues])

    const leaguesDelete = async (id) => {

        let { data } = await deleteLeagues(id)

        data = await data.map(function(val, i) {

            val.image = (<img height="50px" src={val.image}/>)

            val.action = (
                <>
                    <button onClick={() => leaguesDelete(val.id)}>delete</button>
                    <button className="mLeft5" onClick={() => leaguesUpdate(val.id)}>update</button>
                </>
            )

            return val
        })

        setLeaguesData(data)
    }

    const leaguesUpdate = async (id) => {
        setShowLeaguesForm(true)
        setUpdateLeagues(id)
    }

    const addLeaguesSubmit = async () => {

        const league = new FormData()

        league.append("image", imageRef.current.files[0])
        league.append("url", urlRef.current.value)

        if (updateLeagues) {
            league.append("id", updateLeagues)

            let { data } = await editLeagues(league)

            data = await data.map(function(val, i) {

                val.image = (<img height="50px" src={val.image}/>)

                val.action = (
                    <>
                        <button onClick={() => leaguesDelete(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => leaguesUpdate(val.id)}>update</button>
                    </>
                )

                return val
            })

            console.log(data)
            setLeaguesData(data)
        } else {
            // add new
            let { data } = await addLeagues(league)

            data = await data.map(function(val, i) {

                val.image = (<img height="50px" src={val.image}/>)

                val.action = (
                    <>
                        <button onClick={() => leaguesDelete(val.id)}>delete</button>
                        <button className="mLeft5" onClick={() => leaguesUpdate(val.id)}>update</button>
                    </>
                )

                return val
            })

            console.log(data)
            setLeaguesData(data)
        }

        console.log(league)
        setShowLeaguesForm(false)
        setUpdateLeagues(false)
    }

    return (
        <div className="w80 h100vh backColGold">
            <div className="w100 spaceBetween mTop10">
                <p className="pLeft20 text40 textBolder">Lige</p>
                <FontAwesomeIcon
                    onClick={() => setShowLeaguesForm(!showLeaguesForm)}
                    className="text60 pRight20 cursorPointer"
                    icon={showLeaguesForm == true ? faMinusCircle : faPlusCircle}
                />
            </div>

            {showLeaguesForm &&
                (
                    <div className="w100 verticalAlignMid mTop40">
                        <div className="w60 pY30 backColBlack">
                            <div className="w100 spaceBetween colGold">
                                <div className="w48">
                                    <label>URL</label>
                                    <input ref={urlRef} type="text" className="w100" placeholder="Ukucaj url"/>
                                </div>
                                <div className="w48 colGold">
                                    <label>Slika</label>
                                    <input ref={imageRef} type="file" accept="image/png, image/gif, image/jpeg" className="w100" placeholder="Izaberi sliku"/>
                                </div>
                            </div>
                            <button onClick={addLeaguesSubmit}>Submit</button>
                        </div>
                    </div>
                )
            }

            <div className="w100 verticalAlignMid mY20">
                <div className="w90 mTop20 verticalAlignMid">
                    <MUIDataTable
                        title={"Leagues Table"}
                        data={leaguesData}
                        columns={leaguesColumns}
                        options={leaguesOptions}
                        className={"w100"}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminLeagues;